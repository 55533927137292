import { useField } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { Options } from 'react-select';
import FormSelect, { FormSelectProps, IOption } from '..';

interface FormikFormSelectProps extends FormSelectProps {
    name: string;
    customOnChange?: (value: string | number | (string | number)[] | undefined) => void;
}

const FormikFormSelect: FC<FormikFormSelectProps> = ({ name, options, customOnChange, ...props }) => {
    const [field, meta, helpers] = useField(name);

    // Methods.
    const handleOnChange = useCallback((option: IOption | Options<IOption> | null) => {
        const value = option instanceof Array ? option.map((o) => o.value) : option?.value;
        helpers.setValue(value);
        customOnChange?.(value);
    }, [helpers, customOnChange]);

    // Render.
    const error = meta.error;

    const value = useMemo(() => props.isMulti ? options?.filter((option) => field.value?.indexOf(option.value) >= 0) : options?.find((option) => option.value === field.value), [field.value, options, props.isMulti]);

    return <FormSelect {...field} {...props} value={value} onChange={handleOnChange} onBlur={() => helpers.setTouched(true)} error={error} options={options} />;
};

export default FormikFormSelect;
