import Button, { ButtonProps } from 'components/atoms/button/Button';
import React, { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import Group from '../Group';
import { Tabs as TabsWrapper, Tab as TabButton, Wrapper, Notification } from './styles';

export interface Tab {
    title: string;
    notification?: number;
}

export interface Scene {
    [key: number]: ReactNode;
}

export const renderScene = (scene: Scene, activeTab?: number): ReactNode => {
    return scene[activeTab || 0];
};

interface TabsProps {
    tabs: Tab[];
    activeTab?: number;
    onTabClick?: (tab: number) => void;
    button?: ButtonProps;
}

const Tabs: FC<PropsWithChildren<TabsProps>> = ({ activeTab = 0, onTabClick, tabs, button }) => {
    const [active, setActive] = useState<number>(activeTab);

    // Methods.
    const handleOnTabClick = (index: number) => {
        if (onTabClick) {
            onTabClick(index);
        }

        setActive(index);
    };

    // Life cycle
    useEffect(() => {
        if (activeTab !== undefined) {
            setActive(activeTab);
        }
    }, [activeTab]);

    return (
        <Wrapper>
            <TabsWrapper>
                {tabs.map((tab, index) => {
                    const isActive = index === active;

                    return (
                        <TabButton key={index} onClick={() => handleOnTabClick(index)} isActive={isActive}>
                            <Group noMargin>{tab.title}
                                {tab.notification && <Notification>{tab.notification}</Notification>}
                            </Group>
                        </TabButton>
                    );
                })}
            </TabsWrapper>
            {button && <Button {...button} />}
        </Wrapper>
    );
};

export default Tabs;
