import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormCheckProps } from './';
import { lighten } from 'polished';
import Icon from 'components/atoms/Icon';

export const Checkbox = styled.div<{
    checked?: boolean;
    round?: boolean;
}>`
    width: 1rem;
    height: 1rem;
    border: 1px solid ${({ theme }) => theme.greys[100]};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};

    &:hover {
        border-color: ${({ theme }) => theme.greys[100]}; // TODO replace.
    }

    ${({ theme, checked }) =>
        checked &&
        css`
            background-color: ${theme.colors.primary};
            border: none;

            &:hover {
                background-color: ${lighten(0.1, theme.colors.primary)};
            }
        `}
`;

export const HiddenCheckbox = styled.input<FormCheckProps>`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:focus + div {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
    }
`;

export const Label = styled.span`
    color: ${({ theme }) => theme.colors.dark};
    margin-left: 16px;
    font-size: 0.875rem;
    line-height: 0.875rem;
`;

export const Wrapper = styled.label<{
    label?: string;
}>`
    display: ${({ label }) => (label ? 'flex' : 'inline-flex')};
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;

export const StyledIcon = styled(Icon)`
    position: absolute;
`;
