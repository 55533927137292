import { useField } from 'formik';
import React from 'react';
import FormControl, { FormControlProps } from '..';

interface FormikFormControlProps<T extends string | number = string> extends Omit<FormControlProps<T>, 'value' | 'onChange'> {
    name: string;
    customOnChange?: (value: string | number | (string | number)[] | undefined) => void;
}

const FormikFormControl = <T extends string | number>(props: FormikFormControlProps<T>) => {
    const [field, meta, helpers] = useField<T | undefined>(props);
    const error = !!meta.error ? meta.error : undefined;

    const handleOnChange = (value: T) => {
        helpers.setValue(value === '' ? undefined : value);
        props.customOnChange?.(value);
    };

    return <FormControl<T> {...props} value={field.value} onChange={handleOnChange} error={error} />;
};

export default FormikFormControl;
