import { useCallback } from 'react';
import Modal from 'components/organisms/Modal';
import { useModal } from '.';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { FormGroup, FormikFormSelect } from 'components/molecules/form';
import useTenant from 'hooks/api/tenant';
import { useSession } from 'contexts/sessionContext';
import { copyOptions, CopyType } from 'utils/constants/copyTypeConstants';

interface CopyToTenantFormValues {
    tenantId?: string;
    copyType?: CopyType;
}

export interface CopyToTenantModalProps {
    title: string;
    isSoftCopy?: boolean;
    onConfirm: (
        values: CopyToTenantFormValues,
        setErrors: (errors: FormikErrors<CopyToTenantFormValues>) => void,
        hide: () => void
    ) => void;
}

const useCopyToTenantModal = ({ title, isSoftCopy, onConfirm }: CopyToTenantModalProps): [() => void, () => void, boolean] => {
    const initialValues: CopyToTenantFormValues = {};
    const { useSearchTenants } = useTenant();
    const { session } = useSession();
    const { data: tenants = [] } = useSearchTenants({});

    const tenantOptions = tenants
        .filter((t) => t.id !== session?.activeTenantId)
        .map((t) => ({ label: t.name, value: t.id })) || [];

    const submit = async (values: CopyToTenantFormValues, actions: FormikHelpers<CopyToTenantFormValues>) => {
        actions.setSubmitting(true);
        await onConfirm(values, actions.setErrors, hide);
        actions.setSubmitting(false);
    };

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={submit}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <Modal
                        onHide={hide}
                        inProp={inProp}
                        onExited={onExited}
                        title={title}
                        buttons={[
                            {
                                title: 'Bevestigen',
                                onClick: () => handleSubmit(),
                                variant: 'primary',
                                loading: isSubmitting
                            },
                            {
                                title: 'Annuleren',
                                onClick: hide,
                                variant: 'text'
                            }
                        ]}
                    >
                        <Form>
                            <FormGroup label="Platform">
                                <FormikFormSelect name="tenantId" options={tenantOptions} />
                            </FormGroup>

                            <FormGroup label="Hard of soft kopiëren">
                                <FormikFormSelect
                                    name="copyType"
                                    options={copyOptions}
                                    isOptionDisabled={option => isSoftCopy ? option.value === CopyType.HardCopy : false}
                                />
                            </FormGroup>
                        </Form>
                    </Modal>
                )}
            </Formik>
        ),
        [tenantOptions]
    );

    // Methods.
    const showModal = useCallback(
        () => {
            show();
        },
        [show]
    );

    return [showModal, hide, isShowing];
};

export default useCopyToTenantModal;

