import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: .25rem;
`;

export const Tabs = styled.div<{ withButton?: boolean }>`
    display: flex;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.greys[100]};
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: .25rem;
`;

export const Tab = styled.div<{ isActive: boolean }>`
    border: none;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.black : theme.colors.grey)};
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.secondary : theme.colors.white)};
    white-space: nowrap;
    padding: .5rem .75rem;
    border-radius: 50px;

    &:hover {
        cursor: pointer;
    }
`;

export const Notification = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    margin-left: .375rem;
    width: 18px;
    height: 18px;
    border-radius: 0.75rem;
    font-size: 0.625rem;
`;
