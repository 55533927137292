import React, { FC } from 'react';
import { Footer as Wrapper } from './styles';
import Pagination, { PaginationProps } from '../Pagination';

export interface FooterProps extends PaginationProps { }

const Footer: FC<FooterProps> = ({ ...props }) => {
    return (
        <Wrapper>
            <Pagination {...props} />
        </Wrapper>
    );
};

export default Footer;

