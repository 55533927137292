export interface Navigation {
    width: {
        default: number;
        collapsed: number;
    };
}

const navigation: Navigation = {
    width: {
        default: 260,
        collapsed: 5.5
    }
};

export default navigation;

