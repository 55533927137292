export interface Header {
    height: number;
    extraHeight: number;
}

const header: Header = {
    height: 6.5,
    extraHeight: 0
};

export default header;
