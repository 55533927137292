import { H1, Paragraph } from 'components/atoms/text';
import React, { FC, ReactElement } from 'react';
import { BackButton, Wrapper, ButtonWrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import Margin from 'components/atoms/Margin';
import Group from '../Group';

export interface ContentHeadProps {
    title: string;
    children?: ReactElement;
    backTo?: string;
    spaceBetween?: boolean;
}

const ContentHead: FC<ContentHeadProps> = ({ title, children, backTo, spaceBetween }) => {
    const navigate = useNavigate();

    return (
        <Wrapper spaceBetween={spaceBetween}>
            <Group>
                {backTo &&
                    <BackButton onClick={() => navigate(backTo)}>
                        <ButtonWrapper>
                            <Icon name="left" size={1} />
                            <Margin left={.375}>
                                <Paragraph noMargin fontSize={0.875}>
                                    Terug
                                </Paragraph>
                            </Margin>
                        </ButtonWrapper>
                    </BackButton>}
                <H1 noMargin>{title}</H1>
            </Group>
            <div>{children}</div>
        </Wrapper>
    );
};

export default ContentHead;
