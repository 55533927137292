const form = {
    height: {
        normal: 3.25,
        small: 2.5
    },
    padding: {
        horizontal: 16
    },
    fontSize: {
        normal: 0.85,
        small: 0.75
    }
};

export type Form = typeof form;
export default form;
