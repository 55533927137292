import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat, timeFormat } from 'utils/formatters/dateFormatter';
import { Date as DateLabel, Time as TimeLabel, DateWrapper } from './styles';

interface DateTimeProps {
    datetime: string | Date;
}

const DateTime: FC<DateTimeProps> = ({ datetime }) => {
    const { i18n } = useTranslation();
    const date = dateFormat(datetime, i18n.language, '.');
    const time = timeFormat(datetime, i18n.language);

    return (
        <DateWrapper>
            <DateLabel>{date}</DateLabel>
            <TimeLabel>{time}</TimeLabel>
        </DateWrapper>
    );
};

export default DateTime;
