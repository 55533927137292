import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import InvalidFeedback from '../InvalidFeedback';
//import { FormControl as Input, IconBackground, IconWrapper, Wrapper } from './styles';
import { Textarea } from './styles';

export interface FormTextAreaProps<T extends string | number = string> extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
    value?: T;
    onChange: (value: T) => void;
    error?: string;
    className?: string;
    type?: 'text' | 'number';
}

const FormTextArea = <T extends string | number>({ error, value: initialValue, onChange, type = 'text', onBlur, onFocus, ...props }: FormTextAreaProps<T>) => {
    const [value, setValue] = useState<string>(initialValue?.toString() ?? '');
    const [, setIsFocused] = useState<boolean>(false);
    const ref = useRef<HTMLTextAreaElement>(null);

    // Life cycle.
    useEffect(() => {
        if (initialValue === value) {
            return;
        }

        setValue(initialValue?.toString() ?? '');
    }, [initialValue]); // do not add `value` as dependency!

    // Methods.
    const handleOnFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setIsFocused(true);

        if (onFocus) {
            onFocus(event);
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setIsFocused(false);

        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        setValue(value);

        if (onChange) {
            onChange(value as T);
        }
    };

    return (
        <>
            <Textarea ref={ref} {...props} rows={5} value={value} onBlur={handleOnBlur} onFocus={handleOnFocus} onChange={handleOnChange} />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormTextArea;

