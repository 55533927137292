import { useApi } from '..';
import { AddTagGroupInputModel } from './types';

const useTagGroup = () => {

    const { apiPost } = useApi();

    const baseUrl = '/taggroup';

    const addTagGroup = async (values: AddTagGroupInputModel) => {
        const response = await apiPost(baseUrl, values);

        return response;
    };

    return {
        addTagGroup
    };
};

export default useTagGroup;
