import React, { InputHTMLAttributes } from 'react';
import { Checkbox, HiddenCheckbox, Label, StyledIcon, Wrapper } from './styles';
import colors from 'theme/colors';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface FormCheckProps<T extends object = {}> extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    className?: string;
    data?: T;
    id?: string;
    round?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const FormCheck = <T extends object = {}>({ className, label, checked, round, ...props }: FormCheckProps<T>) => {
    return (
        <Wrapper label={label} className={className}>
            <HiddenCheckbox type="checkbox" checked={checked} {...props} />
            <Checkbox round={round} checked={checked}>{checked && <StyledIcon name="check-bold" size={0.75} color={colors.white} />}</Checkbox>
            {label && <Label>{label}</Label>}
        </Wrapper>
    );
};

export default FormCheck;

