import styled from '@emotion/styled';

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 16px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0 0 .75rem .75rem;
`;
