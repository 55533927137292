import { FormikErrors, FormikTouched } from 'formik';
import { CustomError } from 'hooks/api/types';
import { TFunction } from 'i18next';
import { toast } from 'react-toastify';

export const toastError = (errors: any[], t: TFunction) => {
    let fieldErrors = errors.filter(e => e.key !== '').map(e => `- ${t(e.key)}\n`);
    fieldErrors = Array.from(new Set(fieldErrors));
    if (fieldErrors.length > 0) {
        toast.error(`${t('common:errorsFound')}:\n${fieldErrors.join('')}`);
    } else {
        toast.error(t('common:error'));
    }
};

const convertToCamelCase = (input: string) => {
    return input.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

const isArrayKey = (key: string) => {
    return key.includes('[') && key.includes(']');
};

const getArrayIndex = (key: string) => {
    const match = key.match(/\[(\d+)\]/);
    return match ? parseInt(match[1], 10) : -1;
};

const getArrayName = (key: string) => {
    return key.split(/^(.+)\[(\d+)\]$/)[1];
};

export function toFormikErrors<T>(errors: CustomError[]): FormikErrors<T> {
    const result: FormikErrors<T> = {};

    errors.forEach(error => {
        const keys = error.key.split('.');
        let nestedObject: any = result;

        keys.forEach((key, index) => {
            const camelCaseKey = convertToCamelCase(key);

            if(index !== keys.length - 1) {
                if(isArrayKey(key)) {
                    const arrayIndex = getArrayIndex(camelCaseKey);
                    const camelCaseArrayKey = getArrayName(camelCaseKey);

                    if (!nestedObject[camelCaseArrayKey]) {
                        nestedObject[camelCaseArrayKey] = [];
                    }

                    if (!nestedObject[camelCaseArrayKey][arrayIndex]) {
                        nestedObject[camelCaseArrayKey][arrayIndex] = {};
                    }

                    nestedObject = nestedObject[camelCaseArrayKey][arrayIndex];
                } else {
                    nestedObject[camelCaseKey] = nestedObject[camelCaseKey] || {};
                    nestedObject = nestedObject[camelCaseKey];
                }
            } else {
                nestedObject[camelCaseKey] = error.message;
            }
        });
    });

    return result as FormikErrors<T>;
}

export function isTouched(touched: FormikTouched<any>, name: string) {
    if (name.includes('[') && name.includes(']') && name.includes('.')) {
        // looking up array name
        const indexArrayOpen = name.indexOf('[');
        const arrayName = name.substr(0, indexArrayOpen);

        // looking up array index
        const indexArrayClose = name.indexOf(']');
        const indexArrayLength = indexArrayClose - indexArrayOpen - 1;
        const arrayIndex = Number(name.substr(indexArrayOpen + 1, indexArrayLength));

        // looking up variable name
        const indexDot = name.indexOf('.');
        const variableLength = name.length - (indexDot + 1);
        const variableName = name.substr(indexDot + 1, variableLength);

        const array = touched[arrayName] as Array<any>;

        if (!array) {
            return undefined;
        }

        if (!array[arrayIndex]) {
            return undefined;
        }

        if (!array[arrayIndex][variableName]) {
            return undefined;
        }

        return array[arrayIndex][variableName];
    } else {
        return touched[name];
    }
}
