import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ extraHeaderContent?: boolean; isLoading?: boolean; isOpen?: boolean }>`
    flex: 1;
    margin-top: 6rem;
    backdrop-filter: blur(10px);
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    margin-left: 17rem;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-width: calc(100% - ${({ theme }) => theme.navigation.width.default}px);
    min-height: calc(
        100vh - ${({ theme: { header }, extraHeaderContent }) => (extraHeaderContent ? header.height + header.extraHeight : header.height) + 1.5}rem
    );

    ${({ isOpen, theme }) =>
        !isOpen &&
        css`
            margin-left: 6.5rem;
            max-width: calc(100% - ${theme.navigation.width.collapsed}rem - 1.5rem);
        `}

    ${({ isLoading }) =>
        isLoading &&
        css`
            border-radius: 12px;
            min-height: calc(100vh - 3rem);
            margin-top: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        `}
`;

export const Content = styled.div<{ isFull?: boolean }>`
    padding: .5rem 0 0 .5rem;
    height: ${({ isFull }) => (isFull ? '100%' : 'auto')};
`;

export const TopContent = styled.div`
    background: ${({ theme }) => theme.colors.white};
    padding: 0 30px;
    .tabs_inline {
        margin: 0 -1rem;
    }
`;

