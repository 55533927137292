import { useField } from 'formik';
import React from 'react';
import FormTextArea, { FormTextAreaProps } from '..';

interface FormikFormTextAreaProps<T extends string | number = string> extends Omit<FormTextAreaProps<T>, 'value' | 'onChange'> {
    name: string;
}

const FormikFormTextArea = <T extends string | number>(props: FormikFormTextAreaProps<T>) => {
    const [field, meta, helpers] = useField<T>(props);
    const error = !!meta.error ? meta.error : undefined;

    return <FormTextArea {...props} value={field.value} onChange={helpers.setValue} error={error} />;
};

export default FormikFormTextArea;