import styled from '@emotion/styled';

export const Paragraph = styled.p<{ color: string; center?: boolean; noMargin?: boolean, fontSize?: number }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: ${({ fontSize }) => fontSize ? fontSize : 0.875}rem;
    line-height: 1.25rem;
    margin-top: 0;
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : ' 1.25rem')};
    font-weight: 500;
`;

export const ReadMore = styled.span`
    color: ${({ theme }) => theme.colors.dark};
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0;
    font-weight: 700;
    cursor: pointer;
    padding-left: 4px;
`;
