import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransitionStatus, ENTERING, ENTERED, EXITING, EXITED } from 'react-transition-group/Transition';
import { Size } from 'theme';

export const Overlay = styled.div<{ state: TransitionStatus; duration: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 104;
    transition: opacity ${({ duration }) => duration}ms ease;
    opacity: 0;

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            opacity: 1;
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            opacity: 0;
        `}
`;

export const Modal = styled.div<{ state: TransitionStatus; duration: number; size?: keyof Size }>`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    z-index: 10000;
    width: 480px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 2.5rem 0;
    margin-top: -24px;
    max-height: calc(100% - 5rem);
    transition: all ${({ duration }) => duration}ms ease;
    transform: translate(-50%, -50%);
    opacity: 0;

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            margin-top: 0;
            opacity: 1;
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            margin-top: -24px;
            opacity: 0;
    `}

	display: flex;
    flex-direction: column;

    ${({ size }) =>
        size === 'lg' &&
        css`
            @media (min-width: 720px) {
                width: 720px;
            }
        `}
`;

export const Content = styled.div`
    padding: 32px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;

export const Header = styled.div<{ noBody: boolean }>`
    padding: 1.5rem 32px 0;
    padding-bottom: ${({ noBody }) => (noBody ? '1.5rem' : 0)};
`;

export const Footer = styled.div`
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 0 32px 1.5rem 32px;
`;

