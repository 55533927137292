import React, { FC } from 'react';
import { FormSelect as Control, Option, Menu, MenuList, ValueContainer, Placeholder, Indicator } from './styles';
import Select, { InputActionMeta, Props, ValueContainerProps, ControlProps, PlaceholderProps, OptionProps, MenuProps, MenuListProps, GroupBase } from 'react-select';
import InvalidFeedback from '../InvalidFeedback';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import { orderBy } from 'lodash-es';

// See https://react-select.com/typescript#custom-select-props
declare module 'react-select/dist/declarations/src/Select' {
    export interface Props<
        Option,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        IsMulti extends boolean,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Group extends GroupBase<Option>
    > {
        isSmall?: boolean;
    }
}

export interface IOption {
    value: string | number;
    label: string;
    subTitle?: string;
}

export interface FormSelectProps extends Props<IOption, boolean> {
    error?: string;
    isMulti?: boolean;
    isAsync?: boolean;
    isSmall?: boolean;
    value?: readonly IOption[] | IOption | null | undefined;
    options?: ReadonlyArray<IOption> | undefined;
    enableEmptySelection?: boolean;
    placeholder?: string;
}

const CustomValueContainer = ({ children }: ValueContainerProps<IOption, boolean>) => (
    <ValueContainer>{children}</ValueContainer>
);

const CustomControl = ({ children, innerProps, innerRef, isFocused, selectProps }: ControlProps<IOption, boolean>) => (
    <Control ref={innerRef} {...innerProps} isFocused={isFocused} isSmall={selectProps.isSmall} isDisabled={selectProps.isDisabled}>
        {children}
    </Control>
);

const CustomIndicatorsContainer = () => (
    <Indicator><Icon name="chevron-down" size={1.5} /></Indicator>
);

const CustomPlaceholder = ({ children }: PlaceholderProps<IOption, boolean>) => (
    <Placeholder>{children}</Placeholder>
);

const CustomOption = ({ children, innerProps, innerRef, isSelected, isDisabled }: OptionProps<IOption, boolean>) => (
    <Option ref={innerRef} {...innerProps} isSelected={isSelected} isDisabled={isDisabled}>
        {children}
    </Option>
);

const CustomMenu = ({ children, innerRef, innerProps, menuPlacement }: MenuProps<IOption, boolean>) => (
    <Menu ref={innerRef} placement={menuPlacement} {...innerProps}>
        {children}
    </Menu>
);

const CustomMenuList = ({ children, innerRef }: MenuListProps<IOption, boolean>) => (
    <MenuList ref={innerRef}>{children}</MenuList>
);

const FormSelect: FC<FormSelectProps> = ({ error, isSmall, isAsync, placeholder, isDisabled, options, ...props }) => {
    const theme = useTheme();
    placeholder = placeholder ?? 'Selecteren';

    const formattedOptions = props.enableEmptySelection && Array.isArray(options)
        ? [{ value: null, label: placeholder }, ...options]
        : options;

    const orderedOptions = orderBy(formattedOptions, o => o.label.toLowerCase());

    const onInputChange = (inputValue: string, { action, prevInputValue }: InputActionMeta) => {
        switch (action) {
            case 'input-change':
                return inputValue;
            default:
                return prevInputValue;
        }
    };

    const multiValueColor = isDisabled ? theme.greys[100] : theme.colors.secondary;

    return (
        <>
            <Select
                {...props}
                placeholder={placeholder}
                options={orderedOptions}
                isClearable
                isSearchable
                isDisabled={isDisabled}
                onInputChange={onInputChange}
                closeMenuOnSelect={!props.isMulti ?? true}
                isSmall={isSmall}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                components={{
                    Control: CustomControl,
                    IndicatorsContainer: CustomIndicatorsContainer,
                    Placeholder: CustomPlaceholder,
                    ValueContainer: CustomValueContainer,
                    Option: CustomOption,
                    Menu: CustomMenu,
                    MenuList: CustomMenuList,
                }}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 99999 }),
                    singleValue: (base) => ({
                        width: 'calc(100% - 25px)'
                    }),
                    multiValue: (base) => ({
                        ...base,
                        backgroundColor: multiValueColor,
                        borderRadius: '50px',
                        padding: '8px 12px',
                    }),
                    multiValueLabel: (base) => ({
                        ...base,
                        backgroundColor: multiValueColor,
                        color: theme.colors.black,
                        borderRadius: '50px 0px 0px 50px',
                    }),
                    multiValueRemove: (base) => ({
                        ...base,
                        backgroundColor: multiValueColor,
                        color: theme.colors.grey,
                        borderRadius: '0px 50px 50px 0px',
                        ':hover': {
                            color: theme.colors.black,
                        }
                    })
                }}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormSelect;

