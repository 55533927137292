import colors from './colors';
import navigation from './navigation';
import header from './header';
import greys from './greys';
import card from './card';
import form from './form';

export interface Size {
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
}

const theme = {
    colors,
    greys,
    navigation,
    header,
    card,
    form
};

export default theme;
