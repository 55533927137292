import { useApi } from '..';
import { AddCategoryGroupInputModel } from './types';

const useCategoryGroup = () => {

    const { apiPost } = useApi();

    const baseUrl = '/categorygroup';

    const addCategoryGroup = async (values: AddCategoryGroupInputModel) => {
        const response = await apiPost(baseUrl, values);

        return response;
    };

    return {
        addCategoryGroup
    };
};

export default useCategoryGroup;
