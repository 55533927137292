import styled from '@emotion/styled';

export const DateWrapper = styled.div`
    
`;

export const Date = styled.span`
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.dark};
    display: block;
`;

export const Time = styled.span`
    font-size: 0.75rem;
    line-height: 1;
    line-height: 1.5;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.grey};
`;
