import { FormControl } from 'components/molecules/form';
import { FormControlProps } from 'components/molecules/form/FormControl';
import React, { FC } from 'react';

interface TableSearchProps extends FormControlProps { }

const TableSearch: FC<TableSearchProps> = (props) => {
    return <FormControl icon="search" {...props} />;
};

export default TableSearch;
