import React, { FC, PropsWithChildren } from 'react';
import { CardBody as Wrapper } from './styles';

export interface CardBodyProps {
    className?: string;
}

const CardBody: FC<PropsWithChildren<CardBodyProps>> = ({ children, className }) => {
    return <Wrapper className={className}>{children}</Wrapper>;
};

export default CardBody;
