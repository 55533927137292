import { css } from '@emotion/react';
import colors from 'theme/colors';
import greys from 'theme/greys';
import arrowLeft from 'static/icons/chevron-left.svg';
import arrowRight from 'static/icons/chevron-right.svg';
import 'react-datepicker/dist/react-datepicker.css';

// Global stling
export const styles = css`
    *,
    &:before,
    &:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html {
        font-family: 'Satoshi', Helvetica, sans-serif;
    }

    html,
    body {
        margin: 0;
        background-color: ${colors.background};
    }

    #root {
        height: 100%;
    }

    body,
    input,
    textarea,
    button {
        font-family: inherit;
    }

    button {
        outline: none;
        appearance: none;
        cursor: pointer;
        border: none;
        background-color: transparent;
        font-size: inherit;
    }

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .w-md-editor-toolbar {
        background-color: transparent !important;
        border-bottom: none !important;
        padding: 1rem 1rem 0 !important;
    }

    .w-md-editor-text {
        background-color: ${colors.accent};
        color: ${colors.black};
        border-radius: 16px !important;
        margin: 1rem;
    }

    .w-md-editor-input {
        padding: 1rem !important;
        
    }

    .w-md-editor {
        background-color: ${colors.white} !important;
        box-shadow: none !important;
        border: 1px solid ${greys[50]};
        border-radius: 16px !important;
    }

    .w-md-editor-toolbar li > button {
        color: ${colors.black} !important;
        padding: 6px !important;
        height: 32px !important;
 
        svg {
            width: 18px !important;
            height: 18px !important;
        }
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 100%;
        height: 3rem;
        border: 1px solid ${colors.accent};
        border-radius: 12px;
        padding: 0 16px;
        font-size: 1rem;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.2rem ${greys[50]};
        }

        &:hover {
            border-color: ${greys[100]};
        }

        ::placeholder {
            color: ${greys[300]};
        }
    }

    .react-datepicker {
        border: 1px solid ${colors.accent};
        font-family: inherit;
        width: 100%;
        display: flex;
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    
    .react-datepicker__month-container {
        width: 100%;
    }
    .react-datepicker__header {
        background-color: ${colors.background};
        border-color: ${colors.accent};
    }
    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
        background-color: ${colors.background};
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: transparent;
        color: ${colors.black};
    }
    .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
        background-color: ${colors.primary};
    }
    .react-datepicker__week, .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
    }
    .react-datepicker__day-name {
        color: ${colors.text};
    }
    .react-datepicker__day, .react-datepicker__day-name {
        width: 1.75rem;
        height: 1.75rem;
        line-height: 1.75rem;
        border-radius: 50% !important;
        margin: .35rem .25rem;
    }
    .react-datepicker__navigation {
        border: 0;
        background-size: contain;
        background-repeat: no-repeat;
        height: 12px;
        width: 12px;
        opacity: .35;
        margin: 4px;
    }
    .react-datepicker__day--selected {
        background-color: ${colors.primary};
    }
    .react-datepicker__navigation--previous {
        background-image: url(${arrowLeft});
    }
    .react-datepicker__navigation--next {
        background-image: url(${arrowRight});
    }
    
    // TOASTIFY OVERRIDE
    :root {
        --toastify-color-light: #fff;
        --toastify-color-dark: #121212;
        --toastify-color-info: #9DC8FA;
        --toastify-color-success: #C2E999;
        --toastify-color-warning: #FCE8A4;
        --toastify-color-error: #F3B0AB;
        --toastify-color-transparent: rgba(255, 255, 255, 0.7);

        --toastify-text-color-light: #282828;
        --toastify-text-color-dark: #fff;
        
        .Toastify__toast--success {
            background: #F8FFEE;
            border: 1px solid var(--toastify-color-success);
        }

        .Toastify__toast--error {
            background: #FCF1F0;
            border: 1px solid var(--toastify-color-error);
        }

        .Toastify__toast--warning {
            background: #FEFBE8;
            border: 1px solid var(--toastify-color-warning);
        }

        .Toastify__toast--info {
            background: #E8F3FE;
            border: 1px solid var(--toastify-color-info);
        }
    }
`;
