export const dateFormat = (dateTime: string | Date, language: string, separator?: string) => {
    let date = new Date(dateTime).toLocaleDateString(language, { year: 'numeric', month: 'numeric', day: 'numeric' });
    if (separator) {
        date = date.replaceAll('-', separator);
    }
    return date;
};

export const timeFormat = (dateTime: string | Date, language: string, separator?: string) => {
    let time = new Date(dateTime).toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit' });
    if (separator) {
        time = time.replaceAll(':', separator);
    }
    return time;
};
