import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import {
    CopyCategoriesToTenantInputModel,
    EditTenantCategoryOrderInputModel,
    SearchTenantCategoryInputModel,
    TenantCategoryModel
} from './types';

const useTenantCategory = () => {

    const { apiPost, apiPut, apiDelete } = useApi();

    const baseUrl = '/tenantcategory';

    const searchTenantCategories = (values?: SearchTenantCategoryInputModel) => {
        const response = apiPost<TenantCategoryModel[]>(`${baseUrl}/search`, values);

        return response;
    };

    const useSearchTenantCategories = (params?: SearchTenantCategoryInputModel, enabled = true) => {
        const queryKey = ['category.search', params, enabled];

        return useQuery<TenantCategoryModel[], CustomError[]>(queryKey, async () => {
            const response = await searchTenantCategories(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        }, {
            enabled
        });
    };

    const editTenantCategoryVisibility = async (id: string) => {
        const response = await apiPut(`${baseUrl}/${id}/visible`);

        return response;
    };

    const deleteTenantCategoryTag = async (id: string) => {
        const response = await apiDelete(`${baseUrl}/${id}`);

        return response;
    };

    const editTenantCategoryOrder = async (model: EditTenantCategoryOrderInputModel) => {
        const response = await apiPut(`${baseUrl}/order`, model);

        return response;
    };
    
    const copyCategoriesToTenant = (model: CopyCategoriesToTenantInputModel) => {
        return apiPost(`${baseUrl}/copyToTenant`, model);
    };

    return {
        useSearchTenantCategories,
        editTenantCategoryVisibility,
        deleteTenantCategoryTag,
        editTenantCategoryOrder,
        copyCategoriesToTenant
    };
};

export default useTenantCategory;
