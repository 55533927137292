import React, { FC } from 'react';
import FormDrop, { FormDropProps } from '..';
import { useField } from 'formik';

export interface FormikFormDropProps extends FormDropProps {
    name: string;
    height?: number;
}

const FormikFormDrop: FC<FormikFormDropProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField(name);

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;
    return <FormDrop {...props} value={field.value} onChange={helpers.setValue} error={error} />;
};

export default FormikFormDrop;
