import styled from '@emotion/styled';
import Icon from '../Icon';

export const DropdownItem = styled.li`
  position: relative;
  list-style-type: none;
  display: flex;
  min-height: 2.5rem;
  width: 100%;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.greys[50]};
  }
`;

export const DropdownIcon = styled(Icon)`
  margin-right: 8px;
`;
