import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FormGroup = styled.div<{ noMargin?: boolean, stretch: boolean }>`
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '1.25rem')};

    ${({ stretch }) =>
        stretch &&
        css`
            display: flex;
            flex-direction: column;
            flex: 1;
        `};
`;

export const Label = styled.label<{
    required?: boolean;
}>`
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.dark};

    .icon-wrapper {
        margin-left: .25rem;
    }

    ${({ required, theme }) =>
        required &&
        css`
            &:after {
                content: ' *';
                color: ${theme.colors.secondary};
                font-weight: bold;
            }
        `};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
`;

export const Text = styled.span`
    &,
    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 8px;
        color: ${({ theme }) => theme.greys[300]};
        font-size: 0.875rem;
        line-height: 1rem;
    }
`;
