import { useField } from 'formik';
import React, { FC } from 'react';
import FormCheck, { FormCheckProps } from '..';

interface FormikFormCheckProps extends FormCheckProps {
    name: string;
}

const FormikFormCheck: FC<FormikFormCheckProps> = (props) => {
    const [field] = useField(props);

    // Render.
    return <FormCheck checked={field.value} onChange={field.onChange} {...props} />;
};

export default FormikFormCheck;
