import { css } from '@emotion/react';
import Icon from 'components/atoms/Icon';
import { rgba } from 'polished';
import styled from '@emotion/styled';

export const Dropdown = styled.div`
    display: flex;
    position: relative;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
`;

export const Toggle = styled(Icon)`
    margin-left: 8px;
`;

export const Items = styled.ul<{ open?: boolean; placement?: string; width?: number }>`
    position: absolute;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-16px);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: .5rem;
    box-shadow: 0 5px 20px ${({ theme }) => rgba(theme.colors.dark, 0.15)};
    margin-top: 0.5rem;
    z-index: 9;

    ${({ open }) =>
        open &&
        css`
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        `};

        ${({ placement }) =>
        placement === 'left' &&
        css`
               left: 0;
            `};
        ${({ placement }) =>
        placement === 'right' &&
        css`
            right: 0;
            `};
`;
