export enum CopyType
{
    SoftCopy = 0,
    HardCopy = 1
}

export const copyOptions = [
    { label: 'Soft kopiëren', value: CopyType.SoftCopy },
    { label: 'Hard kopiëren', value: CopyType.HardCopy }
];
