import styled from '@emotion/styled';

const InvalidFeedback = styled.span<{ type?: 'error' | 'notification' }>`
  display: block;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ theme, type }) => type === 'notification' ? theme.colors.warning : theme.colors.red};
  margin-top: 0.5rem;
`;

export default InvalidFeedback;
