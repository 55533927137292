import { Global, ThemeProvider } from '@emotion/react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from './theme';
import grid from 'theme/grid';
import { SessionProvider } from 'contexts/sessionContext';
import AppRoutes from './AppRoutes';
import 'i18n';
import { styles } from './styles';
import { MenuProvider } from 'contexts/menuContext';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

setConfiguration(grid);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false
        }
    }
});

Sentry.init({
    dsn: 'https://8b53815ef49a46b8a2dd5019b75fa3f8@utility2.bluenotion.nl/19',
    tracesSampleRate: 0.1,
    enabled: process.env.NODE_ENV !== 'development'
});

const App = () => {
    return (
        <>
            <Global styles={styles} />
            <ThemeProvider theme={theme}>
                <ToastContainer icon={false} />
                <ModalProvider rootComponent={TransitionGroup}>
                    <SessionProvider>
                        <ScreenClassProvider>
                            <QueryClientProvider client={queryClient}>
                                <MenuProvider>
                                    <AppRoutes />
                                </MenuProvider>
                            </QueryClientProvider>
                        </ScreenClassProvider>
                    </SessionProvider>
                </ModalProvider>
            </ThemeProvider>
        </>
    );
};

export default App;
