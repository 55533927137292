import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { FormControl as Input, InlineWrapper, Wrapper } from './styles';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';

export interface FormControlProps<T extends string | number = string> extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    value?: T;
    onChange?: (value: T) => void;
    error?: string;
    notification?: string;
    isSmall?: boolean;
    className?: string;
    type?: 'text' | 'number' | 'password' | 'tel' | 'email';
    icon?: string;
    onIconClick?: () => void;
}

const FormControl = <T extends string | number>({
    error,
    notification,
    value: initialValue,
    onChange,
    type = 'text',
    onBlur,
    onFocus,
    onIconClick,
    icon,
    ...props
}: FormControlProps<T>) => {
    const [value, setValue] = useState<string>(initialValue?.toString() ?? '');
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const { colors } = useTheme();
    const ref = useRef<HTMLInputElement>(null);

    const inputType = type === 'number' ? 'text' : type;

    // Life cycle.
    useEffect(() => {
        if (initialValue === value) {
            return;
        }

        setValue(initialValue?.toString() ?? '');
    }, [initialValue]); // do not add `value` as dependency!

    // Methods.
    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);

        if (onFocus) {
            onFocus(event);
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);

        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setValue(value);
        onChange?.(value as T);
    };

    const handleOnIconClick = () => {
        if (onIconClick) {
            onIconClick();
        } else {
            ref.current?.focus();
        }
    };

    return (
        <>
            <Wrapper isFocused={isFocused} isSmall={props.isSmall} notification={!!notification} error={!!error}>
                <Input ref={ref} type={inputType} {...props} value={value} onBlur={handleOnBlur} onFocus={handleOnFocus} onChange={handleOnChange} />
                {(icon) && (
                    <InlineWrapper isSmall={props.isSmall} onClick={handleOnIconClick}>
                        <Icon name={icon} size={1} color={colors.dark} />
                    </InlineWrapper>
                )}
                {(error || notification) &&
                    <InlineWrapper isSmall={props.isSmall}>
                        <Icon name={error ? 'error' : 'warning'} size={1} color={error ? colors.red : colors.warning} />
                    </InlineWrapper>
                }
            </Wrapper>
            {error && <InvalidFeedback type="error">{error}</InvalidFeedback>}
            {notification && !error && <InvalidFeedback type="notification">{notification}</InvalidFeedback>}
        </>
    );
};

export default FormControl;

