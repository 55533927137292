export interface Language {
    name: string;
    label: string;
}

export const languages: Language[] = [
    { name: 'nl', label: 'Nederlands' },
    { name: 'en', label: 'Engels' },
    { name: 'de', label: 'Duits' },
    { name: 'my', label: 'Maleis' }
];

export const languageOptions = languages.map((language) => ({
    value: language.name,
    label: language.label,
}));
