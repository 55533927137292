import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import { CopyVideosToTenantInputModel, EditVideoStatusInputModel, SearchTenantVideosInputModel, TenantVideoModel } from './types';

const useTenantVideo = () => {
    const { apiPost, apiPut, apiDelete } = useApi();

    const baseUrl = '/TenantVideo';

    const searchTenantVideos = (model: SearchTenantVideosInputModel) => {
        const response = apiPost<TenantVideoModel[]>(`${baseUrl}/search`, model);
        return response;
    };

    const useSearchTenantVideos = (params: SearchTenantVideosInputModel) => {
        const queryKey = ['tenantVideo.search'];

        return useQuery<TenantVideoModel[], CustomError[]>(queryKey, async () => {
            const response = await searchTenantVideos(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const editStatus = (id: string, model: EditVideoStatusInputModel) => {
        const response = apiPut<TenantVideoModel[]>(`${baseUrl}/status/${id}`, model);
        return response;
    };

    const toggleVisibility = (id: string) => {
        const response = apiPut(`${baseUrl}/toggleVisibility/${id}`);
        return response;
    };

    const deleteTenantVideos = (ids: string[]) => {
        const response = apiDelete(`${baseUrl}/delete`, ids);
        return response;
    };

    const copyVideosToTenant = (model: CopyVideosToTenantInputModel) => {
        const response = apiPost(`${baseUrl}/copyToTenant`, model);
        return response;
    };

    return {
        searchTenantVideos,
        useSearchTenantVideos,
        editStatus,
        toggleVisibility,
        deleteTenantVideos,
        copyVideosToTenant
    };
};

export default useTenantVideo;
