import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { lighten, rgba, darken } from 'polished';
import { ButtonProps } from '.';
import Icon from 'components/atoms/Icon';
import { lightenAmount } from 'theme/colors';

export const Button = styled.button<ButtonProps>`
    border: none;
    height: ${({ theme }) => theme.form.height.normal}rem;
    width: ${({ stretch }) => stretch ? '100%' : 'auto'};
    padding: .875rem 1.5rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    ${({ theme, variant, disabled, textVariant }) => {
        const color = theme.colors[variant || 'primary'];
        let textColor = theme.colors[textVariant || 'white'];
        if (color === theme.colors.primary) {
            textColor = textVariant ? textColor : theme.colors.secondary;
        }
        if (color === theme.colors.primary) {
            return css`
                background-color: ${disabled ? rgba(color, 0.5) : color};
                color: ${disabled ? lighten(lightenAmount.disabled, textColor) : textColor};

                &:hover {
                    background-color: ${disabled ? rgba(color, 0.5) : darken(lightenAmount.hover, color)};
                }

                &:active {
                    background-color: ${disabled ? rgba(color, 0.5) : darken(lightenAmount.active, color)};
                }
        `;
        } else if (color === theme.colors.white) {
            return css`
                background-color: ${color};
                border: ${`1px solid ${theme.colors.accent}`};
                
                &:hover {
                    background-color: ${disabled ? theme.colors.white : theme.colors.secondary};
                }

                &:active {
                    background-color: ${disabled ? theme.colors.white : darken(lightenAmount.active, theme.colors.secondary)};
                }
            `;
        } else {
            return css`
                background-color: ${disabled ? lighten(lightenAmount.disabled, color) : color};
                color: ${disabled ? lighten(lightenAmount.disabled, textColor) : textColor};
                
                &:hover {
                    background-color: ${disabled ? lighten(lightenAmount.disabled, color) : lighten(lightenAmount.hover, color)};
                }
            `;
        }
    }}
`;

export const Loading = styled.div<{
    color?: string;
    icon?: boolean;
}>`
    width: 1rem;
    height: 1rem;
    margin-right: ${({ icon }) => (icon ? 'calc(16px + 0.25rem)' : '12px')};
    margin-left: ${({ icon }) => (icon ? 0.25 : 0)}rem;
    border: 2px solid ${({ color }) => color};
    animation: rotate 1s linear infinite;
    border-left-color: transparent;
    border-radius: 50%;
`;

export const StartIcon = styled(Icon)`
    margin-right: .625rem;

    > span {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: nowrap;
    }
`;

export const EndIcon = styled(Icon)`
    margin-left: .625rem;
`;

