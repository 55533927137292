import styled from '@emotion/styled';

export const H4 = styled.h4<{ color: string; center?: boolean; noMargin?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '.5rem')};
`;

export const Wrapper = styled.div`
    display: flex;
    margin-bottom: 0;
`;
