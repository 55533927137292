import React, { FC, PropsWithChildren } from 'react';
import { Modal as Wrapper, Overlay, Content, Header, Footer } from './styles';
import { Transition } from 'react-transition-group';
import { Size } from 'theme';
import Button, { ButtonProps } from 'components/atoms/button/Button';
import Group from 'components/molecules/Group';
import { H3, Paragraph } from 'components/atoms/text';

const DURATION = 300;

export interface ModalProps {
    inProp: boolean;
    onExited: () => void;
    onHide: () => void;
    size?: keyof Size;
    title?: string;
    subtitle?: string;
    buttons?: ButtonProps[];
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ children, inProp, onExited, onHide, size = 'md', title, subtitle, buttons }) => {
    return (
        <Transition in={inProp} onExited={onExited} timeout={DURATION}>
            {(state) => (
                <>
                    <Overlay state={state} duration={DURATION} onClick={onHide} />
                    <Wrapper state={state} duration={DURATION} onClick={(e) => e.stopPropagation()} size={size}>
                        {title && (
                            <Header noBody={!children}>
                                <H3 noMargin={!subtitle}>{title}</H3>
                                {subtitle && <Paragraph noMargin>{subtitle}</Paragraph>}
                            </Header>
                        )}
                        {children && <Content>{children}</Content>}
                        {buttons && (
                            <Footer>
                                <Group stretch size="lg">
                                    {buttons?.map((button, index) => (
                                        <Button key={index} {...button} />
                                    ))}
                                </Group>
                            </Footer>
                        )}
                    </Wrapper>
                </>
            )}
        </Transition>
    );
};

export default Modal;
