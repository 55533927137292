import { CustomError, useApi } from '..';
import { CopyTagsToTenantInputModel, SearchTenantTagInputModel, TenantTagModel } from './types';
import { useQuery } from 'react-query';

const useTenantTag = () => {

    const { apiPost, apiPut, apiDelete } = useApi();

    const baseUrl = '/tenanttag';

    const searchTenantTags = async (values?: SearchTenantTagInputModel) => {
        const response = await apiPost<TenantTagModel[]>(`${baseUrl}/search`, values);

        return response;
    };

    const useSearchTenantTags = (params?: SearchTenantTagInputModel, enabled = true) => {
        const queryKey = ['tag.search', params, enabled];

        return useQuery<TenantTagModel[], CustomError[]>(queryKey, async () => {
            const response = await searchTenantTags(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        }, {
            enabled
        });
    };

    const editTenantTagVisibility = async (id: string) => {
        const response = await apiPut(`${baseUrl}/${id}/visible`);

        return response;
    };

    const deleteTenantTags = async (ids: string[]) => {
        const response = await apiDelete(`${baseUrl}/delete`, ids);

        return response;
    };

    const copyTagsToTenant = (model: CopyTagsToTenantInputModel) => {
        const response = apiPost(`${baseUrl}/copyToTenant`, model);
        return response;
    };

    return {
        searchTenantTags,
        useSearchTenantTags,
        editTenantTagVisibility,
        deleteTenantTags,
        copyTagsToTenant
    };
};

export default useTenantTag;

