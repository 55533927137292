import styled from '@emotion/styled';

export const CardBody = styled.div`
    padding: ${({ theme }) => theme.card.padding}px;
`;

export const CardBackground = styled.div`
    padding: .75rem;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.secondary};
`;
