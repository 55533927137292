import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { readableColor, lighten, rgba } from 'polished';
import { IconButtonProps } from '.';

const lightenAmount = {
    disabled: 0.15,
    hover: 0.05
};

export const Button = styled.button<Omit<IconButtonProps, 'icon'>>`
    border: none;
    width: ${({ theme }) => theme.form.height.normal}rem;
    height: ${({ theme }) => theme.form.height.normal}rem;
    border-radius: ${({ theme, rounded }) => (rounded ? `${theme.form.height.normal / 2}rem` : '50px')};
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ theme, variant, disabled }) => {
        const color = theme.colors[variant || 'black'];

        return css`
            color: ${disabled ? lighten(lightenAmount.disabled, readableColor(color)) : readableColor(color)};
            background-color: ${disabled ? lighten(lightenAmount.disabled, color) : color};

            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.2rem ${rgba(color, 0.5)};
            }

            &:hover {
                cursor: pointer;
                background-color: ${disabled ? lighten(lightenAmount.disabled, color) : lighten(lightenAmount.hover, color)};
            }
        `;
    }}

    ${({ size, theme }) =>
        size === 'sm' &&
        css`
            width: ${theme.form.height.small}rem;
            height: ${theme.form.height.small}rem;
        `}


  ${({ size }) =>
        size === 'lg' &&
        css`
            width: 3rem;
            height: 3rem;
        `}
`;

export const Loading = styled.div<{
    color?: string;
}>`
    width: 1rem;
    height: 1rem;
    border: 2px solid ${({ color }) => color};
    animation: rotate 1s linear infinite;
    border-left-color: transparent;
    border-radius: 50%;
`;

