import { useMenuContext } from 'contexts/menuContext';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Wrapper, Content as Inner, TopContent } from './styles';

export interface ContentProps {
    extraHeaderContent?: boolean;
    topContent?: ReactNode;
    isLoading?: boolean;
    isFull?: boolean;
}

const Content: FC<PropsWithChildren<ContentProps>> = ({ children, extraHeaderContent, topContent, isLoading, isFull }) => {
    const { isOpen } = useMenuContext();

    return (
        <Wrapper extraHeaderContent={extraHeaderContent} isLoading={isLoading} isOpen={isOpen}>
            <TopContent>
                {topContent}
            </TopContent>
            <Inner isFull={isFull}>{children}</Inner>
        </Wrapper>
    );
};

export default Content;

