import { css } from '@emotion/react';
import styled from '@emotion/styled';


export const Textarea = styled.textarea<{ error?: boolean; notification?: boolean; }>`
    width: 100%;
    border-color: ${({ theme }) => theme.greys[100]};
    border-radius: 12px;
    min-height: ${({ theme }) => theme.form.height.normal}px;
    font-size: 1rem;
    max-width: 100%;
    line-height: 1.5rem;
    padding: .5rem 16px;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ theme, error, notification }) => {
        if (error) {
            return css`
            border: 2px solid ${theme.colors.red};
        `;
        } else if (notification) {
            return css`
            border: 2px solid ${theme.colors.warning};
        `;
        } else {
            return css`
            border: 2px solid ${theme.colors.accent};
        `;
        }
    }}

    &:focus {
        outline: none;
        border: 2px solid ${({ theme }) => theme.colors.secondary};
    }

    ::placeholder {
        color: ${({ theme }) => theme.greys[300]};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.greys[50]};
    }
`;
