import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Drop = styled.div<{ image?: string, height?: number, hide?: boolean }>`
    background-color: ${({ theme, image }) => image ? 'transparent' : theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.accent};
    border-radius: 50%;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;

    &:hover {
        cursor: pointer;
    }

    ${({ image }) =>
        image &&
        css`
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            background-image: url("${image}");
        `}

    ${({ hide }) =>
        hide &&
        css`
            display: none;
        `}
`;

export const Content = styled.div``;

export const Wrapper = styled.div`
    position: relative;
    display: inline-flex;
    position: relative;
    align-items: flex-end;
`;

export const Remove = styled.button`
    position: absolute;
    z-index: 2;
    cursor: pointer;
    top: .2rem;
    left: .2rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    display: inline-flex;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    border: 0;
`;

export const Label = styled.div`
    font-size: 0.875rem;
    padding: 0 16px;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    display: inline-flex;
    align-items: center;
    margin-top: 1rem;
    border-radius: 12px;
`;
