import React, { InputHTMLAttributes } from 'react';
import { Checkbox, HiddenCheckbox, Label, Wrapper } from './styles';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface FormRadioProps<T extends object = {}> extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    className?: string;
    data?: T;
    id?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const FormRadio = <T extends object = {}>({ className, label, checked, ...props }: FormRadioProps<T>) => {
    return (
        <Wrapper label={label} className={className}>
            <HiddenCheckbox type="radio" checked={checked} {...props} />
            <Checkbox checked={checked} />
            {label && <Label>{label}</Label>}
        </Wrapper>
    );
};

export default FormRadio;

