import { TagModel } from 'hooks/api/tag/types';

export const getTagType = (tag: TagModel) => {
    const isLink = tag.page != null || tag.url != null;
    const isLocation = tag.location?.latitude != null && tag.location?.longitude != null && tag.location?.description != null;

    if (!isLink && !isLocation) {
        return 'Standaard';
    }

    const array: string[] = [];

    if (isLink) {
        array.push('Link');
    }

    if (isLocation) {
        array.push('Locatie');
    }

    return array.join(', ');
};
