const cdnUrl = 'https://cdn.bluenotion.nl';

export const cdnFileUrl = (filename: string, params?: any) => {
    const args = params
        ? Object.keys(params).map((k: any) => {
            return `${k}=${params[k]}`;
        }).reduce((a, b) => {
            if (a) {
                return `${a}&${b}`;
            }

            return b;
        }, '')
        : '';

    let result = `${cdnUrl}/${filename}`;
    if (args.length > 0) {
        result += `?${args}&auto-orient`;
    }
    return result;
};

export const resolvedCdn = (imageHash: string, width = 64, height = 64) => {
    return cdnFileUrl(imageHash, {
        resize: `${width}x${height}^`,
        extent: `${width}x${height}`,
        crop: `${width}x${height}`,
        quality: 90,
        optimize: true,
        progressive: true,
    });
};

export const cdnUri = (imageHash: string, width = 64, height = 64) => {
    return { uri: resolvedCdn(imageHash, width, height) };
};

export const cdnString = (imageHash: string, width = 64, height = 64) => {
    return resolvedCdn(imageHash, width, height);
};
