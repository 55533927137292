import React, { FC, ReactNode } from 'react';
import { FormGroup as Wrapper, Label, Header, Text } from './styles';

interface FormGroupProps {
    label?: string;
    required?: boolean;
    text?: string | ReactNode;
    noMargin?: boolean;
    stretch?: boolean;
}

const FormGroup: FC<React.PropsWithChildren<FormGroupProps>> = ({ label, text, children, required, noMargin, stretch = false }) => {
    return (
        <>
            <Wrapper noMargin={noMargin} stretch={stretch}>
                {(label || text) && (
                    <Header>
                        {
                            label &&
                            <Label required={required}>
                                {label}
                            </Label>
                        }
                        {text && <Text>{text}</Text>}

                    </Header>
                )}
                {children}
            </Wrapper>
        </>
    );
};

export default FormGroup;
