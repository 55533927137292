import styled from '@emotion/styled';

export const H3 = styled.h3<{ color: string; center?: boolean; noMargin?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '1rem')};
`;
