import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Pagination = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.85rem;
    color: ${({ theme }) => theme.colors.black};
    justify-content: space-between;
    width: 100%;
`;

export const Right = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const Select = styled.select`
    height: 2rem;
    margin: 0 .25rem;
    border: 1px solid ${({ theme }) => theme.colors.accent};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 .25;
    font-size: .75rem;
`;

export const Button = styled.button<{ canNavigate?: boolean }>`
    display: flex;
    background-color: transparent;
    border: none;
    align-items: center;
    opacity: 0.5;

    ${({ canNavigate }) =>
        canNavigate &&
        css`
            opacity: .8;

            &:hover {
                cursor: pointer;
            }
        `}
`;
