import styled from '@emotion/styled';

export const H1 = styled.h1<{ color: string; center?: boolean; noMargin?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '2.5rem')};
`;
