import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import commonNl from './nl/common.json';
import navigationNl from './nl/navigation.json';
import pagesOverviewNl from './nl/pagesOverview.json';
import pageEditNl from './nl/pageEdit.json';
import videosOverviewNl from './nl/videosOverview.json';
import videoEditNl from './nl/videoEdit.json';
import tagsEditNl from './nl/tagsEdit.json';
import categoriesEditNl from './nl/categoriesEdit.json';
import settingsNl from './nl/settings.json';
import htmlEditorNl from './nl/htmlEditor.json';
import headTagsNl from './nl/headTags.json';
import subTagsNl from './nl/subTags.json';
import tagsOverviewNl from './nl/tagsOverview.json';

const resources = {
    nl: {
        common: commonNl,
        navigation: navigationNl,
        pagesOverview: pagesOverviewNl,
        pageEdit: pageEditNl,
        videosOverview: videosOverviewNl,
        videoEdit: videoEditNl,
        tagsEdit: tagsEditNl,
        categoriesEdit: categoriesEditNl,
        settings: settingsNl,
        htmlEditor: htmlEditorNl,
        headTags: headTagsNl,
        subTags: subTagsNl,
        tagsOverview: tagsOverviewNl
    }
};

export const languages = ['nl'] as const;

const ns = Object.keys(resources.nl);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'nl',
        fallbackLng: 'nl',
        supportedLngs: languages,
        debug: process.env.NODE_ENV === 'development',
        ns,
        defaultNS: 'common',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
