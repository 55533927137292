import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RoundButtonProps } from '.';
import { lighten, rgba } from 'polished';

export const Button = styled.button<RoundButtonProps>`
    width: 3rem;
    height: 3rem;
    flex: 0 0 3rem;
    border-radius: 50%;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${({ shadow, theme }) => (shadow ? `0 3px 6px ${rgba(theme.colors.black, 0.06)}` : undefined)};

    &:hover {
        cursor: pointer;
    }

    ${({ theme, variant, light }) =>
        light ?
            css`
                background: ${`linear-gradient(to bottom, ${theme.colors.white} 0%, ${lighten(0.15, theme.colors.secondary)} 100%);`}
                border: 1px solid  ${lighten(0.15, theme.colors.secondary)};
            ` :
            css`
                background-color: ${(!variant ? theme.colors.secondary : theme.colors[variant])};
                background: ${(!variant ? `linear-gradient(90deg, ${lighten(0.1, theme.colors.secondary)}, ${theme.colors.secondary})` : undefined)};
            `
    }

    ${({ size }) =>
        size === 'sm' &&
        css`
            width: 2rem;
            height: 2rem;
            flex: 0 0 2rem;
        `}

    ${({ size }) => size === 'lg' && css``}
`;

