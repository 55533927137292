import React, { FC, PropsWithChildren } from 'react';
import { Size } from 'theme';
import { Group as Wrapper } from './styles';

export interface GroupProps {
    spaceBetween?: boolean;
    right?: boolean;
    stretch?: boolean;
    center?: boolean;
    size?: keyof Size;
    bordered?: boolean;
    noMargin?: boolean;
    gap?: number;
}

const Group: FC<PropsWithChildren<GroupProps>> = ({ children, ...props }) => {
    return <Wrapper {...props}>{children}</Wrapper>;
};

export default Group;
