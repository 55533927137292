import update from 'immutability-helper';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Wrapper } from './styles';
import { Button } from 'components/atoms/button';
import Margin from 'components/atoms/Margin';
import { SortItem } from './SortItem';

interface SortProps<T> {
    items: T[],
    onChangeOrder: (items: T[]) => Promise<void>;
    idKey: (item: T) => string;
    nameKey: (item: T) => string;
}

export function Sort<T>(props: SortProps<T>) {
    const [items, setItems] = useState(props.items);
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    // Solves initial dragging bug
    useEffect(() => {
        moveItem(0, 0);
    }, [props.items]);

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setItems((prevItems: T[]) =>
            update(prevItems, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevItems[dragIndex] as T],
                ],
            }),
        );
    }, []);

    const saveOrder = useCallback(async () => {
        setIsLoadingSave(true);

        await props.onChangeOrder(items);

        setIsLoadingSave(false);
    }, [items]);

    const renderMenuItem = useCallback(
        (item: T, index: number) => {
            return (
                <SortItem
                    key={props.idKey(item)}
                    index={index}
                    id={props.idKey(item)}
                    name={props.nameKey(item)}
                    moveItem={moveItem}
                />
            );
        },
        [],
    );

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Wrapper>{items.map((item, i) => renderMenuItem(item, i))}</Wrapper>
            </DndProvider>
            <Margin top={2}>
                <Button stretch title="Volgorde opslaan" loading={isLoadingSave} onClick={saveOrder} />
            </Margin>
        </>
    );
}
