import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ spaceBetween?: boolean }>`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.greys[100]};
    padding-bottom: 1.25rem;
    margin-bottom: 1.125rem;

    ${({ spaceBetween }) =>
        spaceBetween
        && css`
                  justify-content: space-between;
              `
    };
`;

export const BackButton = styled.button`
    border: none;
    height: ${({ theme }) => theme.form.height.normal}rem;
    padding: .875rem 1.5rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    font-weight: 500;
    transition: 0.1s ease;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.greys[100]};
    margin-right: 1.5rem;

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

