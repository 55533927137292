import React, { FC, useMemo, useState, useEffect } from 'react';
import Content from 'components/atoms/Content';
import ContentHead from 'components/molecules/ContentHead';
import { useNavigate, useParams } from 'react-router';
import { Form, Formik, FormikHelpers } from 'formik';
import { Col, Row } from 'react-grid-system';
import { FormGroup, FormikFormControl, FormikFormSelect } from 'components/molecules/form';
import { Button } from 'components/atoms/button';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import FormikFormTextArea from 'components/molecules/form/FormTextArea/formik';
import Group from 'components/molecules/Group';
import Margin from 'components/atoms/Margin';
import { CardBackground } from 'components/atoms/card/CardBody/styles';
import FormRadio from 'components/molecules/form/FormRadio';
import FormikFormDrop from 'components/molecules/form/FormDrop/formik';
import FormikFormHex from 'components/molecules/form/FormHex/formik';
import { Hr } from 'components/atoms/Hr/styles';
import { toast } from 'react-toastify';
import { EditTagInputModel, TagTranslationInputModel } from 'hooks/api/tag/types';
import { useSession } from 'contexts/sessionContext';
import usePage from 'hooks/api/page';
import useTag from 'hooks/api/tag';
import { toastError, toFormikErrors } from 'utils/errorHelper';
import FormikFormSwitch from 'components/molecules/form/FormSwitch/formik';
import { cdnString } from 'utils/cdnHelper';
import Label from 'components/atoms/Label';
import { useConfirmModal } from 'hooks/modals';
import { TagGroupTagModel } from 'hooks/api/tagGroupTag/types';
import useTagGroupTag from 'hooks/api/tagGroupTag';
import { useTranslation } from 'react-i18next';
import useTenantVideo from 'hooks/api/tenantVideo';
import { TagsOverviewTabs } from '../Overview';
import { Paragraph } from 'components/atoms/text';
import { IOption } from 'components/molecules/form/FormSelect';
import { languageOptions } from 'utils/constants/languageConstants';
import FormArray from 'components/molecules/form/FormArray';

type TagEditParams = {
    id: string;
}

interface TagEditProps {
    action: 'edit' | 'add';
}

const TagEdit: FC<TagEditProps> = ({ action }) => {
    const { t } = useTranslation('tagsEdit');
    const [show] = useConfirmModal();
    const navigate = useNavigate();
    const params = useParams<TagEditParams>();
    const editing = params.id != null;

    const { session } = useSession();
    const { deleteTagGroupTag } = useTagGroupTag();

    const { useSearchPages } = usePage();
    const { data: pages = [] } = useSearchPages({ tenantId: session?.activeTenantId });

    const { useSearchTenantVideos } = useTenantVideo();
    const { data: videos = [] } = useSearchTenantVideos({});

    const { addOrEditTag, useSearchTags } = useTag();
    const { data: tags = [], refetch: refetchTag } = useSearchTags({ id: params.id, combineTags: false }, editing);
    const tag = tags.length > 0 ? tags[0] : null;

    const [urlType, setUrlType] = useState<'existing' | 'custom'>('existing');

    const pageOptions = pages.map((p) => ({ label: p.name, value: p.id }));
    const videoOptions = videos.map((v) => ({ label: v.video.name, value: v.video.id }));

    useEffect(() => {
        if (tag == null) {
            return;
        }

        if (pageOptions.some((option) => option.value === tag.page?.id)) {
            setUrlType('existing');
        } else {
            setUrlType('custom');
        }
    }, [tag]);

    const initialValues = useMemo<EditTagInputModel>(() => ({
        id: tag?.id,
        name: tag?.name ?? '',
        hasLink: tag?.url != null || tag?.page != null,
        hasLocation: tag?.location?.latitude != null && tag?.location?.longitude != null && tag?.location?.description != null,
        url: tag?.url,
        pageId: tag?.page?.id,
        latitude: tag?.location?.latitude,
        longitude: tag?.location?.longitude,
        locationName: tag?.location?.name,
        locationDescription: tag?.location?.description,
        locationMarkerColor: tag?.location?.markerColor,
        locationLogo: tag?.location?.logo != null ? cdnString(tag.location?.logo.hash) : undefined,
        videoIds: tag?.videos?.map((video) => video.id) ?? [],
        translations: tag?.translations ?? [],
    }), [tag]);

    const submit = async (values: EditTagInputModel, actions: FormikHelpers<EditTagInputModel>) => {
        const newValues = { 
            ...values,
            translations: values.translations.filter(tt => Object.keys(tt).length > 0)
        };

        if (!values.hasLink) {
            newValues.url = undefined;
        }

        if (!values.hasLocation) {
            newValues.locationDescription = undefined;
            newValues.locationName = undefined;
            newValues.latitude = undefined;
            newValues.longitude = undefined;
            newValues.locationMarkerColor = undefined;
            newValues.locationLogo = undefined;
        }

        const response = await addOrEditTag(newValues);

        if (response.ok) {
            navigate(`/admin/tags?tab=${TagsOverviewTabs.Tags}`);
            toast.success(t('saveSuccess'));
        } else if (!response.ok && response.errors) {
            const errors = toFormikErrors<EditTagInputModel>(response.errors);
            actions.setErrors(errors);
            const toastErrors = response.errors.map((error) => {
                if (error.key.includes('translations')) {
                    error = {
                        ...error,
                        key: t('common:translations')
                    };
                }
                return error;
            });
            toastError(toastErrors, t);
        }

        actions.setSubmitting(false);
    };

    const handleUrlTypeChange = (type: 'existing' | 'custom', setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        if (type === 'custom') {
            setFieldValue('pageId', null);
        } else {
            setFieldValue('url', null);
        }

        setUrlType(type);
    };

    const onDeleteTagGroupTag = (tag: TagGroupTagModel) => {
        show({
            title: 'Tag loskoppelen',
            text: 'Weet je zeker dat je deze tag wilt loskoppelen?',
            onConfirm: () => handleDeleteTagGroupTag(tag),
        });
    };

    const handleDeleteTagGroupTag = async (tagGroupTag: TagGroupTagModel) => {
        if (tag == null) {
            return;
        }

        const response = await deleteTagGroupTag(tagGroupTag.id);

        if (response.ok) {
            refetchTag();
            toast.success('Tag succesvol losgekoppeld.');
        } else {
            toast.error('Er is iets misgegaan bij het loskoppelen van de tag');
        }
    };

    const navigateToEditTag = (tag: TagGroupTagModel) => {
        navigate(`/admin/tags/edit/${tag.tag.id}`);
    };

    const renderNameTranslationFields = (index: number, translations: TagTranslationInputModel[]) => {
        const isOptionDisabled = (opt: IOption) => {
            return translations.some((vt, i) => i !== index && vt.language === opt.value);
        };

        return (
            <Group stretch key={index}>
                <FormGroup label={t('name')} stretch>
                    <FormikFormControl placeholder={t('name')} name={`translations[${index}].name`} />
                </FormGroup>
                <FormGroup label={t('common:language')} stretch>
                    <FormikFormSelect
                        placeholder={t('common:language')}
                        name={`translations[${index}].language`}
                        options={languageOptions}
                        isOptionDisabled={isOptionDisabled}
                    />
                </FormGroup>
            </Group>
        );
    };
    
    return (
        <>
            <Content>
                <>
                    <Formik<EditTagInputModel>
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={submit}
                    >
                        {({ isSubmitting, isValid, setFieldValue, values }) => (
                            <Form>
                                <ContentHead spaceBetween title={action === 'edit' ? t('editTags') : t('add')} backTo={`/admin/tags?tab=${TagsOverviewTabs.Tags}`}>
                                    <Group>
                                        <Button type="submit" variant={action === 'edit' ? 'orange' : 'primary'} disabled={!isValid} loading={isSubmitting}>
                                            {action === 'edit' ? t('edit') : t('common:save')}
                                        </Button>
                                    </Group>
                                </ContentHead>
                                <Row>
                                    <Col md={8}>
                                        <Card>
                                            <CardHeader title={t('baseInformation')} />
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup label={t('name')} required>
                                                            <FormikFormControl name="name" placeholder={t('name')} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup label={t('common:translations')}>
                                                            <FormArray name="translations" renderFields={renderNameTranslationFields} addText={t('common:addTranslation')} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardHeader title={t('link')} />
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormikFormSwitch
                                                            name="hasLink"
                                                            label={t('hasLink')}
                                                        />
                                                        {values.hasLink &&
                                                            <Margin top={1}>
                                                                <CardBackground>
                                                                    <FormGroup>
                                                                        <FormRadio
                                                                            name="existing"
                                                                            onChange={() => handleUrlTypeChange('existing', setFieldValue)}
                                                                            label={t('existing')}
                                                                            checked={urlType === 'existing'}
                                                                        />
                                                                    </FormGroup>
                                                                    {
                                                                        urlType === 'existing' &&
                                                                        <FormGroup>
                                                                            <FormikFormSelect name="pageId" options={pageOptions} />
                                                                        </FormGroup>
                                                                    }
                                                                    <FormGroup>
                                                                        <FormRadio
                                                                            name="custom"
                                                                            onChange={() => handleUrlTypeChange('custom', setFieldValue)}
                                                                            label={t('custom')}
                                                                            checked={urlType === 'custom'}
                                                                        />
                                                                    </FormGroup>
                                                                    {
                                                                        urlType === 'custom' &&
                                                                        <FormGroup>
                                                                            <FormikFormControl name="url" placeholder={t('url')} />
                                                                        </FormGroup>
                                                                    }
                                                                </CardBackground>
                                                            </Margin>
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardHeader title={t('location')} />
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormikFormSwitch
                                                            name="hasLocation"
                                                            label={t('hasLocation')}
                                                        />
                                                    </Col>
                                                </Row>
                                                {values.hasLocation &&
                                                    <Margin top={1}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup label={t('locationName')}>
                                                                    <FormikFormControl name="locationName" placeholder={t('locationName')} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup label={t('latitude')} required>
                                                                    <FormikFormControl<number> name="latitude" placeholder={t('latitude')} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup label={t('longitude')} required>
                                                                    <FormikFormControl<number> name="longitude" placeholder={t('longitude')} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Hr />
                                                        <FormGroup label={t('locationLogo')}>
                                                            <FormikFormDrop name="locationLogo" buttonText={t('locationFormDropButtonText')} />
                                                        </FormGroup>
                                                        <FormGroup label={t('locationDescription')} required>
                                                            <FormikFormTextArea name="locationDescription" placeholder={t('locationDescription')} />
                                                        </FormGroup>
                                                        <FormGroup label={t('locationMarkerColor')}>
                                                            <FormikFormHex name="locationMarkerColor" />
                                                        </FormGroup>
                                                    </Margin>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        {
                                            tag != null && tag?.tagGroupTags.length > 0 &&
                                            <Col md={12}>
                                                <Card>
                                                    <CardHeader title="Tagstructuur" />
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Margin bottom={.5}>
                                                                    <Paragraph noMargin>Hoofdtag:</Paragraph>
                                                                </Margin>
                                                                <Group><Label text={tag.name} /></Group>
                                                                <Margin bottom={.5} top={.5}>
                                                                    <Paragraph noMargin>Samenvoeging van:</Paragraph>
                                                                </Margin>
                                                                <Group noMargin gap={.5}>
                                                                    {
                                                                        tag.tagGroupTags.map((t) =>
                                                                            <Label
                                                                                text={t.tag.name}
                                                                                icon="close"
                                                                                onIconClick={() => onDeleteTagGroupTag(t)}
                                                                                onClick={() => navigateToEditTag(t)}
                                                                            />
                                                                        )
                                                                    }
                                                                </Group>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }
                                        <Col md={12}>
                                            <Card>
                                                <CardHeader
                                                    title={t('videos')}
                                                    subtitle={t('videoSubTags')}
                                                />
                                                <CardBody>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormikFormSelect name="videoIds" isMulti options={videoOptions} />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </>
            </Content>
        </>
    );
};

export default TagEdit;
