import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { useApi, CustomError } from '..';
import { EditTenantSettingsInputModel, SearchTenantsInputModel, TenantModel, TenantSettingsModel } from './types';

const useTenant = () => {
    const { apiPost, apiPut, apiGet } = useApi();

    const baseUrl = '/tenant';

    const searchTenants = async (model: SearchTenantsInputModel) => {
        const response = await apiPost<TenantModel[]>(`${baseUrl}/search`, model);
        return response;
    };

    const useSearchTenants = (params: SearchTenantsInputModel) => {
        const queryKey = ['company.search', params];

        return useQuery<TenantModel[], CustomError[]>(queryKey, async () => {
            const response = await searchTenants(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const editActiveTenantId = (id: string) => {
        const response = apiPut(`${baseUrl}/activeTenantId/${id}`);
        return response;
    };

    const editTenantSettings = async (model: EditTenantSettingsInputModel) => {
        const fd = new FormData();
        appendObject(model, fd);

        const response = await apiPut(`${baseUrl}/settings`, fd);
        return response;
    };

    const tenantSettings = async () => {
        const response = await apiGet<TenantSettingsModel>(`${baseUrl}/settings`);
        return response;
    };

    const useTenantSettings = () => {
        const queryKey = ['tenantSettings'];

        return useQuery<TenantSettingsModel, CustomError[]>(queryKey, async () => {
            const response = await tenantSettings();

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? {};
        });
    };


    return {
        useSearchTenants,
        editActiveTenantId,
        editTenantSettings,
        useTenantSettings
    };
};

export default useTenant;
