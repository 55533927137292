import React, { Ref, useImperativeHandle } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    ColumnDef,
    flexRender,
    Row,
    getSortedRowModel,
    Table as ReactTable,
    ColumnSort,
} from '@tanstack/react-table';
import { StyledTable, Th, Tr, Td, Wrapper, TableWrapper, ClickableTh, NoResultsInfo } from './styles';
import Margin from 'components/atoms/Margin';
import Search from './components/Search';
import Footer from './components/Footer';
import Icon from 'components/atoms/Icon';
import defaultTo from 'lodash-es/defaultTo';

export interface RowProps<T extends object> {
    onClick?: (item: T) => void;
    toggleExpandOnClick?: boolean;
}

interface TableProps<T extends object> {
    data: any[];
    columns: ColumnDef<any>[];
    search?: boolean;
    row?: RowProps<T>;
    sorting?: ColumnSort[];
}

const Table = <T extends object & { id: string }>({ data, columns, search, row: rowProps, sorting }: TableProps<T>, ref: Ref<ReactTable<T>>) => {
    const table = useReactTable({
        data,
        columns,
        initialState: {
            sorting,
            pagination: {
                pageSize: defaultTo(parseInt(window.localStorage.getItem('pageSize') ?? '50'), 50),
            }
        },
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        autoResetPageIndex: false
    });

    const {
        setGlobalFilter,
        getState,
        getCanPreviousPage,
        getCanNextPage,
        nextPage,
        previousPage,
        getPageCount,
        setPageSize
    } = table;

    const realSetPageSize = (pageSize: number) => {
        setPageSize(pageSize);
        window.localStorage.setItem('pageSize', pageSize.toString());
    };

    useImperativeHandle(ref, () => table, []);

    return (
        <Wrapper>
            {search && (
                <Margin bottom={1}>
                    <Search filter={getState().globalFilter ?? ''} setFilter={setGlobalFilter} />
                </Margin>
            )}
            <TableWrapper>
                <StyledTable>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <Th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <ClickableTh
                                                        clickable={header.column.getCanSort()}
                                                        {
                                                        ...{
                                                            onClick: header.column.getToggleSortingHandler(),
                                                        }
                                                        }
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {
                                                            {
                                                                asc: <Icon name="chevron-up" size={1} />,
                                                                desc: <Icon name="chevron-down" size={1} />,
                                                            }[header.column.getIsSorted() as string] ?? null
                                                        }
                                                    </ClickableTh>
                                                </>
                                            )}
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getPaginationRowModel().rows.map(row => {
                            // The row's onClick should always take precedence over the toggle expansion of sub rows on row click.
                            const onRowClick = (row: Row<T>) => rowProps?.onClick
                                ? rowProps.onClick(row.original)
                                : rowProps?.toggleExpandOnClick
                                    ? row.toggleExpanded(!row.getIsExpanded())
                                    : null;

                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <Td key={cell.id} canClick={!!rowProps?.onClick} onClick={() => onRowClick(row)} width={cell.column.getSize()}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </tbody>
                </StyledTable>
                {table.getPaginationRowModel().rows.length === 0 &&
                    <NoResultsInfo>
                        Geen resultaten gevonden
                    </NoResultsInfo>
                }
                <Footer
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageIndex={getState().pagination.pageIndex}
                    getPageCount={getPageCount}
                    gotoPage={table.setPageIndex}
                    getCanPreviousPage={getCanPreviousPage}
                    getCanNextPage={getCanNextPage}
                    setPageSize={realSetPageSize}
                    pageSize={getState().pagination.pageSize}
                />
            </TableWrapper>
        </Wrapper>
    );
};

const TableWithRef = React.forwardRef(Table) as <T extends object>(props: TableProps<T> & { ref?: Ref<ReactTable<T>> }) => ReturnType<typeof Table>;

export default TableWithRef;
