import React, { useCallback, useState } from 'react';
import Modal from 'components/organisms/Modal';
import { useModal } from './';
import { ButtonProps } from 'components/atoms/button/Button';

type ConfirmProps = {
    title: string;
    text: string;
    onConfirm?: () => void | Promise<void>;
    onCancel?: () => void;
    buttons?: ButtonProps[];
}

const useConfirmModal = (): [(props: ConfirmProps) => void, () => void, boolean] => {
    const [props, setProps] = useState<ConfirmProps>();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        if(props?.onConfirm) {
            const response = props.onConfirm();
            if(response instanceof Promise) {
                setIsLoading(true);
                await response;
                setIsLoading(false);
            }
        }
        handleCancel();
    };
    
    const handleCancel = () => {
        props?.onCancel?.();
        hide();
    };

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal
                onHide={handleCancel}
                inProp={inProp}
                onExited={onExited}
                title={props?.title}
                subtitle={props?.text}
                buttons={props?.buttons ?? [
                    {
                        title: 'Annuleren',
                        onClick: handleCancel,
                        variant: 'white'
                    },
                    {
                        title: 'Bevestigen',
                        onClick: handleConfirm,
                        variant: 'red',
                        loading: isLoading
                    }
                ]}
            />
        ),
        [props, isLoading]
    );

    // Methods.
    const showModal = useCallback(
        (props: ConfirmProps) => {
            setProps(props);
            show();
        },
        [show]
    );

    return [showModal, hide, isShowing];
};

export default useConfirmModal;
