import { useCallback, useState } from 'react';
import Modal from 'components/organisms/Modal';
import { useModal } from '.';
import { toast } from 'react-toastify';
import { orderBy } from 'lodash-es';
import { Sort } from 'components/organisms/Sort';

type ChangeOrderProps<T> = {
    items: T[];
    orderKey: string[];
    idKey: (item: T) => string;
    nameKey: (item: T) => string;
    onSave: (items: T[]) => Promise<boolean>;
    refetchData: () => void;
}

function useChangeOrder<T>(): [(props: ChangeOrderProps<T>) => void, () => void, boolean] {
    const [props, setProps] = useState<ChangeOrderProps<T>>();

    const onChangeOrder = async (items: T[]) => {
        const saved = await props?.onSave(items);

        if (saved) {
            props?.refetchData();
            toast.success('Volgorde is succesvol aangepast!');
            hide();
        } else {
            toast.error('Volgorde kon niet aangepast worden.');
        }
    };

    const orderedItems = orderBy(props?.items ?? [], props?.orderKey ?? []);

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal
                onHide={hide}
                inProp={inProp}
                onExited={onExited}
                title="Volgorde wijzigen"
            >
                <Sort
                    items={orderedItems}
                    idKey={props?.idKey ?? (() => '')}
                    nameKey={props?.nameKey ?? (() => '')}
                    onChangeOrder={onChangeOrder}
                />
            </Modal>
        ),
        [props]
    );

    // Methods.
    const showModal = useCallback(
        (props: ChangeOrderProps<T>) => {
            setProps(props);
            show();
        },
        [show]
    );

    return [showModal, hide, isShowing];
}

export default useChangeOrder;

