import { useField } from 'formik';
import React, { FC } from 'react';
import FormSwitch, { FormSwitchProps } from '..';

interface FormikFormSwitchProps extends FormSwitchProps {
    name: string;
}

const FormikFormSwitch: FC<FormikFormSwitchProps> = (props: FormikFormSwitchProps) => {
    const [field, meta] = useField(props);
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    // Render.
    return <FormSwitch checked={field.value} onChange={field.onChange} error={error} {...props} />;
};

export default FormikFormSwitch;