import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GroupProps } from '.';

export const Group = styled.div<GroupProps>`
    display: flex;
    justify-content: ${({ spaceBetween, right, center }) => (spaceBetween ? 'space-between' : right ? 'flex-end' : center ? 'center' : 'flex-start')};
    align-items: center;
    z-index: 2;
    flex-wrap: wrap;

    ${({ gap }) =>
        gap &&
        css`
                gap: ${gap}rem;
            `};

    ${({ stretch }) =>
        stretch &&
        css`
                flex: 1;
            `};

    > * {
        margin-right: ${({ size, noMargin }) => (size === 'lg' ? 16 : noMargin ? 0 : 8)}px;

            ${({ spaceBetween, right }) =>
        spaceBetween &&
        right &&
        css`
                :only-child {
                    margin-left: auto;
                }
        `};

        ${({ right, size }) =>
        right &&
        css`
                margin-right: 0;
                margin-left: ${(size === 'lg' ? 16 : 8)}px;
        `};

        ${({ bordered, theme }) =>
        bordered &&
        css`
                &:not(:last-of-type) {
                    border-right: 1px solid ${theme.colors.accent};
                }
        `};

        &:last-child {
            margin-right: 0;
        }
    }
`;

