import React, { FC, MouseEvent, PropsWithChildren } from 'react';
import { Button as Wrapper, Loading, StartIcon, EndIcon } from './styles';
import colors, { Colors, lightenAmount } from 'theme/colors';
import { lighten, readableColor } from 'polished';

export interface ButtonProps {
    onClick?: (event?: MouseEvent) => void;
    variant?: keyof Colors;
    textVariant?: keyof Colors;
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    startIcon?: string;
    endIcon?: string;
    type?: 'button' | 'submit';
    title?: string;
    stretch?: boolean;
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({ loading, disabled, onClick, children, variant, textVariant, startIcon, endIcon, title, stretch, ...props }) => {
    // Button's methods.
    const handleOnClick = (event?: MouseEvent) => {
        if (onClick && (!loading || disabled)) {
            onClick(event);
        }
    };

    // Render.
    const icon = !!(startIcon || endIcon);
    const color = colors[variant || 'primary'];
    let textColor = colors[textVariant || (color === colors['primary'] ? 'secondary' : 'white')];
    if (color === textColor && !textVariant) {
        textColor = readableColor(color);
    }

    const iconColor = textColor ?? readableColor(color);

    return (
        <Wrapper
            startIcon={startIcon}
            endIcon={endIcon}
            loading={loading}
            disabled={disabled || loading}
            variant={variant}
            textVariant={textVariant}
            onClick={handleOnClick}
            stretch={stretch}
            {...props}
        >
            {!endIcon && loading ? (
                <Loading icon={icon} color={iconColor} />
            ) : (
                startIcon && <StartIcon name={startIcon} color={disabled ? lighten(lightenAmount.disabled, iconColor) : iconColor} size={1} />
            )}
            {title ?? children}
            {endIcon &&
                (loading ? (
                    <Loading icon={icon} color={textColor ?? readableColor(color)} />
                ) : (
                    <EndIcon size={1} name={endIcon} color={disabled ? lighten(lightenAmount.disabled, iconColor) : iconColor} />
                ))}
        </Wrapper>
    );
};

export default Button;

