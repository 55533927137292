import styled from '@emotion/styled';

export const Wrapper = styled.div(props => ({
    padding: '1em',
    backgroundColor: props.theme.colors.secondary,
    borderRadius: '.5rem',
}));

export const Row = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
}));

export const AddButton = styled.button(props => ({
    display: 'inline-flex',
    gap: '.4rem',
    alignItems: 'center',
    fontSize: '.875rem',

    '.icon-wrapper > span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
    }
}));

export const RemoveButton = styled.button(props => ({
    marginLeft: '.5rem',

    '.icon-wrapper > span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
    }
}));

