import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const SortItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.secondary};
    display: flex;
    flex-direction: row;
    height: 50px;
    cursor: move;
    margin-bottom: .25rem;
    border-radius: 8px;
    padding: 0 1rem 0 .5rem;
`;

export const SortItemName = styled.div`
    margin-left: .5rem;
    font-weight: 700;
    font-size: 16px;
`;
