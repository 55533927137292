import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors } from 'theme/colors';

interface LabelProps {
    color?: Colors;
    isFull?: boolean;
    clickable: boolean;
}

export const Label = styled.div<LabelProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 50px;
    color: ${({ theme }) => theme.colors.dark};
    background-color: ${({ theme }) => theme.colors.secondary};
    ${({ isFull }) => isFull && css`flex: 1;`}
    ${({ clickable }) => clickable && css`cursor: pointer;`}
    white-space: nowrap;
`;
