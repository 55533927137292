import React, { FC } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/molecules/table';
import Dropdown from 'components/molecules/Dropdown';
import { useConfirmModal } from 'hooks/modals';
import { useNavigate } from 'react-router';
import FormSwitch from 'components/molecules/form/FormSwitch';
import Label from 'components/atoms/Label';
import Group from 'components/molecules/Group';
import DateTime from 'components/atoms/DateTime';
import { toast } from 'react-toastify';
import { TenantCategoryModel } from 'hooks/api/tenantCategory/types';
import useTenantCategory from 'hooks/api/tenantCategory';
import { MergeTag } from '..';
import { FormCheck } from 'components/molecules/form';
import { useTranslation } from 'react-i18next';

interface HeadTagsProps {
    tableRef: any;
    categories: TenantCategoryModel[];
    onChangeMergeTags: (mergeTag: MergeTag) => void;
    mergeCategories: MergeTag[];
    selectAllCategories: () => void;
}

const HeadTags: FC<HeadTagsProps> = props => {
    const { tableRef, categories, onChangeMergeTags, mergeCategories, selectAllCategories } = props;
    const { t, i18n } = useTranslation('headTags');
    const [show] = useConfirmModal();
    const navigate = useNavigate();
    const { useSearchTenantCategories, editTenantCategoryVisibility, deleteTenantCategoryTag } = useTenantCategory();
    const { refetch } = useSearchTenantCategories();

    const onDelete = (id: string) => {
        show({
            title: t('delete.popupTitle'),
            text: t('delete.popupText'),
            onConfirm: () => handleDeleteCategory(id),
        });
    };

    const handleDeleteCategory = async (id: string) => {
        const response = await deleteTenantCategoryTag(id);

        if (response.ok) {
            refetch();
            toast.success(t('delete.success'));
        } else {
            toast.error(t('delete.error'));
        }
    };

    const onVisibilityChange = async (category: TenantCategoryModel) => {
        const response = await editTenantCategoryVisibility(category.id);

        if (response.ok) {
            refetch();
            toast.success(t('visibility.success'));
        } else {
            toast.error(t('visibility.error'));
        }
    };

    const onCheckboxClick = (tenantCategory: TenantCategoryModel) => {
        onChangeMergeTags({
            id: tenantCategory.category.id,
            name: tenantCategory.category.name,
            reference: tenantCategory.reference
        });
    };

    const columns = React.useMemo<ColumnDef<TenantCategoryModel>[]>(
        () => [
            {
                id: 'select',
                header: () => <FormCheck readOnly onClick={() => selectAllCategories()} checked={categories.length > 0 && mergeCategories.length === categories.length} />,
                footer: props => props.column.id,
                cell: props => <FormCheck readOnly onClick={() => onCheckboxClick(props.row.original)} checked={mergeCategories.some((t) => t.id === props.row.original.category.id)} />,
                enableGlobalFilter: false,
                size: 1
            },
            {
                id: 'order',
                accessorFn: row => row.order.toString(),
                header: () => 'Volgorde',
                footer: props => props.column.id,
                size: 1
            },
            {
                id: 'name',
                accessorKey: 'category.name',
                header: () => t('table.headers.name'),
                cell: props => <button onClick={() => navigate(`category/edit/${props.row.original.category.id}`)}>{props.cell.getValue() as string}</button>
            },
            {
                accessorKey: 'translation',
                header: () => t('table.headers.translation'),
                accessorFn: row => row.category.translations.map(translation => translation.name).join(', '),
                cell: props => (
                    <div>
                        {props.row.original.category.translations.map((translation, index) => (
                            <div key={index}>{translation.language}: {translation.name}</div>
                        ))}
                    </div>
                )
            },
            {
                id: 'merged',
                header: () => 'Samengevoegd',
                accessorFn: props => props.category.categoryGroupCategories.length.toString(),
                cell: props => props.getValue() as number > 0 &&
                    <Group>
                        <Label text={props.getValue() as string} />
                    </Group>
            },
            {
                accessorKey: 'subTags',
                header: () => t('table.headers.subTags'),
                accessorFn: row => row.category.tags.length,
                cell: props =>
                    <Group>
                        <Label text={props.cell.getValue() as string} />
                    </Group>,
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'created',
                header: () => t('table.headers.created'),
                accessorFn: row => new Date(row.category.created),
                footer: props => props.column.id,
                cell: props => <DateTime datetime={props.cell.getValue() as Date} />,
                enableGlobalFilter: false
            },
            {
                enableGlobalFilter: false,
                accessorKey: 'visibility',
                header: () => t('table.headers.visibility'),
                cell: props =>
                    <FormSwitch defaultChecked={props.row.original.visible} onChange={() => onVisibilityChange(props.row.original)} />,
            },
            {
                accessorKey: 'actions',
                header: () => t('table.headers.actions'),
                cell: props =>
                    <Dropdown placement="right" icon={{ name: 'dots', size: 1 }}
                        items={
                            [
                                { title: t('table.actions.edit'), onClick: () => navigate('category/edit/' + props.row.original.category.id) },
                                { title: t('table.actions.delete'), onClick: () => onDelete(props.row.original.id) },
                            ]
                        }
                    />,
                enableGlobalFilter: false,
                maxSize: 1
            },
        ],
        [i18n, mergeCategories]
    );

    return (
        <Table<TenantCategoryModel>
            ref={tableRef}
            data={categories}
            columns={columns}
            //sorting={[{ id: 'order', desc: false }]}
        />
    );
};

export default HeadTags;
