import { Button } from 'components/atoms/button';
import { ButtonProps } from 'components/atoms/button/Button';
import Item, { DropdownItemProps } from 'components/atoms/DropdownItem';
import { IconProps } from 'components/atoms/Icon';
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown as Wrapper, Header, Items, Toggle } from './styles';

export interface DropdownProps {
    items: DropdownItemProps[];
    icon?: IconProps;
    placement?: 'left' | 'right';
    buttonProps?: ButtonProps;
}

const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({ children, items, icon, placement = 'left', buttonProps }) => {
    const ref = useRef<HTMLUListElement>(null);
    const [open, setOpen] = useState(false);

    // Dropdown's methods.
    const toggle = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        setOpen((open) => !open);
    }, []);

    const handleOnClick = useCallback((event: MouseEvent) => {
        setOpen(false);
    }, []);

    // Dropdown's life cycle.
    useEffect(() => {
        if (open) {
            window.addEventListener('click', handleOnClick);
        }

        return () => window.removeEventListener('click', handleOnClick);
    }, [open, handleOnClick]);

    return (
        <Wrapper>
            <Header onClick={toggle}>
                {typeof children === 'string' ? <Button {...buttonProps}>{children}</Button> : children}
                {
                    !buttonProps?.endIcon &&
                    <>
                        {
                            icon ? (
                                <Toggle {...icon} />
                            ) : (
                                <Toggle name="chevron-down" color="grey" />
                            )
                        }
                    </>
                }
            </Header>
            <Items ref={ref} open={open} placement={placement}>
                {items.map((item, index) => (
                    <Item key={item.title || index} {...item} />
                ))}
            </Items>
        </Wrapper>
    );
};

export default Dropdown;
