import Group from 'components/molecules/Group';
import React, { FC } from 'react';
import { Colors } from 'theme/colors';
import Icon from '../Icon';
import Margin from '../Margin';
import { Label as Wrapper } from './styles';

export interface LabelProps {
    color?: Colors,
    text: string,
    isFull?: boolean,
    icon?: string;
    onIconClick?: () => void;
    onClick?: () => void;
}

const Label: FC<LabelProps> = ({ text, isFull, icon, onIconClick, onClick }) => {
    return (
        <Wrapper isFull={isFull} clickable={onClick != null} onClick={onClick}>
            <Group center>
                {text}
                {icon && <Margin left={.25}><button type="button" onClick={onIconClick}><Icon color="grey" size={.8} name={icon} /></button></Margin>}
            </Group>
        </Wrapper>
    );
};

export default Label;
