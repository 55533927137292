import { useApi } from '..';

const useTagGroupTag = () => {

    const { apiDelete } = useApi();

    const baseUrl = '/taggrouptag';

    const deleteTagGroupTag = async (id: string) => {
        const response = await apiDelete(`${baseUrl}/${id}`);

        return response;
    };

    return {
        deleteTagGroupTag
    };
};

export default useTagGroupTag;
