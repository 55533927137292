import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconButton } from 'components/atoms/button';
import { rgba } from 'polished';

export const Wrapper = styled.div`
`;

export const TableWrapper = styled.div`
    display: block;
    max-width: 100%;
    position: relative;
    box-shadow: 0 3px 6px ${({ theme }) => rgba(theme.colors.black, 0.06)};
    border-radius: .75rem;
`;

export const StyledTable = styled.table<{ fixedLayout?: boolean; }>`
    width: 100%;
    border-collapse: collapse;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: .75rem .75rem 0 0;

    ${({ fixedLayout }) =>
        fixedLayout &&
        css`
            table-layout: fixed;
        `}
`;

export const Th = styled.th`
    margin-left: 4px;
    text-align: left;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 1.25rem 14px;
    font-weight: 500;
    border-bottom: 1px solid ${({ theme }) => theme.greys[100]};

    &:first-of-type {
        padding-left: 16px;
        border-top-left-radius: 6px;
    }

    &:last-of-type {
        padding-right: 16px;
        border-top-right-radius: 6px;
    }

    span {
        display: inline-flex;
        align-items: center;
    }
`;

export const Td = styled.td<{ depth?: number; isActive?: boolean; isDragTd?: boolean; canClick?: boolean; width?: number }>`
    color: ${({ theme }) => theme.colors.text};
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.greys[100]};
    width: ${({ width }) => `${width}rem` || 'auto'};

    &:first-of-type {
        padding-left: 16px;
    }

    &:last-of-type {
        padding-right: 16px;
    }

    ${({ isActive, theme }) =>
        isActive &&
        css`
            background-color: ${theme.colors.background};
        `}

    ${({ canClick }) =>
        canClick &&
        css`
            cursor: pointer;
        `}

    ${({ isDragTd }) =>
        isDragTd &&
        css`
            cursor: move;
            width: 0;
        `}

    ${({ depth, theme }) =>
        depth &&
        depth > 0 &&
        css`
            background-color: ${theme.colors.background};

            &:first-of-type {
                padding-left: 32px;
            }
        `}
`;

export const Tr = styled.tr<{ oneven?: boolean; expanded?: boolean }>`
    ${({ expanded, theme }) =>
        expanded &&
        css`
        background-color: ${theme.colors.background};
        
        table td {
            background-color: ${theme.colors.white};
        }
    `}
`;

export const ClickableTh = styled.div<{ clickable?: boolean; }>`
    display: flex;

    ${({ clickable }) =>
        clickable &&
        css`
        cursor: pointer;
    `}
`;

export const HeaderIconButton = styled(IconButton)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1;
`;

export const NoResultsInfo = styled.div`
    padding: 3rem;
    border-bottom: 1px solid ${({ theme }) => theme.greys[100]};
    text-align: center;
    background-color: ${({ theme }) => theme.colors.white};
`;
