import { lazy, Suspense } from 'react';
import Loading from 'components/atoms/Loading';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import TagEdit from 'components/pages/admin/tags/Edit';

// Templates
const Default = lazy(() => import('components/templates/Default'));
const Account = lazy(() => import('components/templates/Account'));
const Admin = lazy(() => import('components/templates/Admin'));

// Account pages
const Login = lazy(() => import('components/pages/account/Login'));
const LoginWithUserName = lazy(() => import('components/pages/account/LoginWithUserName'));

// Admin pages
const PagesOverview = lazy(() => import('components/pages/admin/pages/Overview'));
const PageEdit = lazy(() => import('components/pages/admin/pages/Edit'));
const VideosOverview = lazy(() => import('components/pages/admin/videos/Overview'));
const VideoEdit = lazy(() => import('components/pages/admin/videos/Edit'));
const MenusOverview = lazy(() => import('components/pages/admin/menus/Overview'));
const CompanySettings = lazy(() => import('components/pages/admin/settings/company'));
const TagsOverview = lazy(() => import('components/pages/admin/tags/Overview'));
const CategoryEdit = lazy(() => import('components/pages/admin/categories/Edit'));

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route index element={<Default />} />

                    <Route path="account" element={<Account />}>
                        <Route path="login" element={<Login />} />
                        {
                            process.env.NODE_ENV === 'development' &&
                            <Route path="loginwithusername/:username" element={<LoginWithUserName />} />
                        }
                    </Route>
                    <Route path="admin" element={<Admin />}>
                        <Route index element={<Navigate to="/admin/videos" replace />} />
                        <Route path="pages">
                            <Route path="" element={<PagesOverview />} />
                            <Route path="edit/:id" element={<PageEdit action="edit" />} />
                            <Route path="new" element={<PageEdit action="add" />} />
                        </Route>
                        <Route path="videos">
                            <Route path="" element={<VideosOverview />} />
                            <Route path="edit/:id" element={<VideoEdit action="edit" />} />
                            <Route path="new" element={<VideoEdit action="add" />} />
                        </Route>
                        <Route path="tags">
                            <Route path="" element={<TagsOverview />} />
                            <Route path="category/edit/:id" element={<CategoryEdit action="edit" />} />
                            <Route path="category/new" element={<CategoryEdit action="add" /> } />
                            <Route path="edit/:id" element={<TagEdit action="edit" />} />
                            <Route path="new" element={<TagEdit action="add" />} />
                        </Route>
                        <Route path="menus">
                            <Route path="" element={<MenusOverview />} />
                        </Route>
                        <Route path="settings/company">
                            <Route path="" element={<CompanySettings />} />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default AppRoutes;
