import React, { FC, useCallback, useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/molecules/table';
import Dropdown from 'components/molecules/Dropdown';
import { useNavigate } from 'react-router';
import FormSwitch from 'components/molecules/form/FormSwitch';
import { toast } from 'react-toastify';
import { getTagType } from 'utils/tagHelper';
import useTenantTag from 'hooks/api/tenantTag';
import { TenantTagModel } from 'hooks/api/tenantTag/types';
import { FormCheck } from 'components/molecules/form';
import { MergeTag } from '..';
import Label from 'components/atoms/Label';
import Group from 'components/molecules/Group';
import { useTranslation } from 'react-i18next';
import { useConfirmModal } from 'hooks/modals';

interface SubTagsProps {
    tableRef: any;
    tags: TenantTagModel[];
    mergeTags: MergeTag[];
    setMergeTags: React.Dispatch<React.SetStateAction<MergeTag[]>>;
    selectAllTags: () => void;
    onChangeMergeTags: (mergeTag: MergeTag) => void;
    openDeleteTagPopup: boolean;
    setOpenDeleteTagPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubTags: FC<SubTagsProps> = props => {
    const { 
        tableRef, tags, onChangeMergeTags, mergeTags, setMergeTags, 
        selectAllTags, openDeleteTagPopup, setOpenDeleteTagPopup 
    } = props;

    const { t, i18n } = useTranslation('subTags');
    const navigate = useNavigate();

    const { useSearchTenantTags, editTenantTagVisibility, deleteTenantTags } = useTenantTag();
    const { refetch } = useSearchTenantTags();

    const countSelectedTags = mergeTags.length;
    const [tenantTagToDelete, setTenantTagToDelete] = useState<TenantTagModel>();

    const [showDeleteTagModal] = useConfirmModal();
    const showDeleteTagPopup = useCallback(() => {
        const deleteType = tenantTagToDelete || countSelectedTags === 1 ? 'single' : 'multiple';

        const handelCancel = () => {
            setOpenDeleteTagPopup(!openDeleteTagPopup);
            setTenantTagToDelete(undefined);
        };

        showDeleteTagModal({
            title: t(`delete.${deleteType}.popupTitle`),
            text: t(`delete.${deleteType}.popupText`),
            onConfirm: handleDeleteTenantTags,
            onCancel: handelCancel
        });
    }, [openDeleteTagPopup, countSelectedTags]);

    useEffect(() => {
        if (!openDeleteTagPopup) {
            return;
        }

        showDeleteTagPopup();
    }, [openDeleteTagPopup]);

    const handleDeleteTenantTags = async () => {
        const deleteType = tenantTagToDelete || countSelectedTags === 1 ? 'single' : 'multiple';

        const selectedTenantTagIds = tags
            .filter(tg => mergeTags.some(mt => mt.id === tg.tag.id))
            .map(tg => tg.id);

        const tenantTagIds = tenantTagToDelete ? [tenantTagToDelete.id] : selectedTenantTagIds;
        const response = await deleteTenantTags(tenantTagIds);

        if (response.ok) {
            await refetch();
            setMergeTags([]);
            toast.success(t(`delete.${deleteType}.success`));
        } else {
            toast.error(t(`delete.${deleteType}.error`));
        }
    };
    const onVisibilityChange = async (tag: TenantTagModel) => {
        const response = await editTenantTagVisibility(tag.id);

        if (response.ok) {
            refetch();
            toast.success(t('visibility.success'));
        } else {
            toast.error(t('visibility.error'));
        }
    };

    const onCheckboxClick = (tenantTag: TenantTagModel) => {
        onChangeMergeTags({
            id: tenantTag.tag.id,
            name: tenantTag.tag.name,
            reference: tenantTag.reference
        });
    };

    const columns = React.useMemo<ColumnDef<TenantTagModel>[]>(
        () => [
            {
                header: () => <FormCheck readOnly onClick={() => selectAllTags()} checked={tags.length > 0 && mergeTags.length === tags.length} />,
                id: 'selecteer',
                footer: props => props.column.id,
                cell: props => <FormCheck readOnly onClick={() => onCheckboxClick(props.row.original)} checked={mergeTags.some((t) => t.id === props.row.original.tag.id)} />,
                enableGlobalFilter: false,
                size: 1
            },
            {
                id: 'name',
                accessorKey: 'tag.name',
                header: () => t('table.headers.name'),
                footer: props => props.column.id,
                cell: props => <button onClick={() => navigate('edit/' + props.row.original.tag.id)}>{props.cell.getValue() as string}</button>
            },
            {
                accessorKey: 'translation',
                header: () => t('table.headers.translation'),
                accessorFn: row => row.tag.translations.map(translation => translation.name).join(', '),
                cell: props => (
                    <div>
                        {props.row.original.tag.translations.map((translation, index) => (
                            <div key={index}>{translation.language}: {translation.name}</div>
                        ))}
                    </div>
                )
            },
            {
                accessorKey: 'merged',
                header: () => t('table.headers.merged'),
                accessorFn: props => props.tag.tagGroupTags?.length,
                cell: props => props.getValue() as number > 0 &&
                    <Group>
                        <Label text={props.getValue() as string} />
                    </Group>,
                enableGlobalFilter: false
            },
            {
                accessorKey: 'type',
                header: () => t('table.headers.type'),
                cell: props => getTagType(props.row.original.tag),
                enableGlobalFilter: false
            },
            {
                accessorKey: 'link',
                header: () => t('table.headers.link'),
                cell: props => props.row.original.tag.page != null
                    ? `/${props.row.original.tag.page.slug}`
                    : props.row.original.tag.url ?? '-',
                enableGlobalFilter: false
            },
            {
                accessorKey: 'category',
                header: () => t('table.headers.category'),
                enableGlobalFilter: false
            },
            {
                enableGlobalFilter: false,
                accessorKey: 'visibility',
                header: () => t('table.headers.visibility'),
                cell: props =>
                    <FormSwitch checked={props.row.original.visible} onChange={() => onVisibilityChange(props.row.original)} />,
            },
            {
                enableGlobalFilter: false,
                accessorKey: 'actions',
                header: () => t('table.headers.actions'),
                cell: props => {
                    const tagId = props.row.original.tag.id;
                    const tenantTagToDelete = props.row.original;

                    const onDeleteTenantTag = () => {
                        setTenantTagToDelete(tenantTagToDelete);
                        setOpenDeleteTagPopup(!openDeleteTagPopup);
                    };
                    
                    return (
                        <Dropdown 
                            placement="right" 
                            icon={{ name: 'dots', size: 1 }} 
                            items={[
                                { title: t('table.actions.edit'), onClick: () => navigate(`edit/${tagId}`) },
                                { title: t('table.actions.delete'), onClick: onDeleteTenantTag },
                            ]}
                        />
                    );
                },
                maxSize: 1
            },
        ],
        [mergeTags, i18n]
    );

    return (
        <Table<TenantTagModel>
            ref={tableRef}
            data={tags}
            columns={columns}
            sorting={[{ id: 'name', desc: false }]}
        />
    );
};

export default SubTags;
