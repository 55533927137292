import React, { FC, ReactNode } from 'react';
import { DropdownIcon, DropdownItem as Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

export interface DropdownItemProps {
    title?: string;
    onClick?: () => void;
    to?: string;
    icon?: string;
    content?: ReactNode;
}

const DropdownItem: FC<DropdownItemProps> = ({ title, onClick, to, icon, content }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    // DropdownItem's methods.
    const handleOnClick = () => {
        if (to) {
            navigate(to);
        } else if (onClick) {
            onClick();
        }
    };

    // Render.
    return (
        <Wrapper onClick={handleOnClick}>
            {icon && <DropdownIcon name={icon} color={theme.greys[300]} />}
            {
                title || content
            }
        </Wrapper>
    );
};

export default DropdownItem;
