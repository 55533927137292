import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import { EditPageInputModel, PageModel, SearchPageInputModel } from './types';

const usePage = () => {

    const { apiGet, apiPost, apiPut, apiDelete } = useApi();

    const baseUrl = '/page';

    const addOrEditPage = async (values: EditPageInputModel) => {
        const call = values.id == null
            ? apiPost(baseUrl, values)
            : apiPut(baseUrl, values);

        const response = await call;

        return response;
    };

    const getPageById = async (id: string) => {
        const response = await apiGet<PageModel>(`${baseUrl}/${id}`);

        return response;
    };

    const searchPages = async (values: SearchPageInputModel) => {
        const response = await apiPost<PageModel[]>(`${baseUrl}/search`, values);

        return response;
    };

    const useSearchPages = (params: SearchPageInputModel, enabled = true) => {
        const queryKey = ['page.search', params, enabled];

        return useQuery<PageModel[], CustomError[]>(queryKey, async () => {
            const response = await searchPages(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        }, {
            enabled
        });
    };

    const deletePages = async (ids: string[]) => {
        const response = await apiDelete(`${baseUrl}/delete`, ids);

        return response;
    };

    return {
        addOrEditPage,
        getPageById,
        searchPages,
        deletePages,
        useSearchPages
    };
};

export default usePage;
