import Button, { ButtonProps } from 'components/atoms/button/Button';
import Group from 'components/molecules/Group';
import React, { FC } from 'react';
import { Size } from 'theme';
import { CardHeader as Wrapper, Title, Subtitle, Text } from './styles';

export interface CardHeaderProps {
    title: string;
    subtitle?: string;
    buttons?: ButtonProps[];
    border?: boolean;
    size?: keyof Size;
}

const CardHeader: FC<CardHeaderProps> = ({ title, subtitle, border, size, buttons }) => {
    return (
        <Wrapper border={border}>
            <Text>
                <Title size={size}>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </Text>
            <Group>
                {buttons && buttons.map(button => <Button {...button} />)}
            </Group>
        </Wrapper>
    );
};

export default CardHeader;

