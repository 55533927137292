import React, { FC, InputHTMLAttributes } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { FormSwitch as Switch, SubTitle, Wrapper, LabelBefore, LabelBeforeWrapper } from './styles';

export interface FormSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    labelBefore?: string;
    subTitle?: string;
    reverse?: boolean;
    error?: string;
    checked?: boolean;
}

const FormSwitch: FC<FormSwitchProps> = (props) => {
    return (
        <>
            <Wrapper reverse={props.reverse}>
                <LabelBeforeWrapper>
                    {props.labelBefore && <LabelBefore>{props.labelBefore}</LabelBefore>}
                </LabelBeforeWrapper>
                <Switch>
                    <input type="checkbox" {...props} />
                    <div className="switch" />
                </Switch>
                <div>
                    {props.label && <span>{props.label}</span>}
                    {props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
                </div>
            </Wrapper>
            {props.error && <InvalidFeedback>{props.error}</InvalidFeedback>}
        </>
    );
};

export default FormSwitch;
