import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Size } from 'theme';

export const CardHeader = styled.div<{ border?: boolean }>`
    padding: 1.5rem 1.5rem .5rem 1.5rem;
    display: flex;
    border-bottom: ${({ border, theme }) => (border ? `1px solid ${theme.colors.accent}` : 'none')};
    justify-content: space-between;
`;

export const Title = styled.h3<{ size?: keyof Size }>`
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.dark};

    ${({ size }) =>
        size === 'sm' &&
        css`
            font-size: 1rem;
            line-height: 1rem;
        `}

    ${({ size }) =>
        size === 'xl' &&
        css`
            font-size: 1.5rem;
            line-height: 1.5rem;
        `}
`;

export const Subtitle = styled.span`
    font-size: 1rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.greys[300]};
    display: inline-flex;
`;

export const Text = styled.div``;
