import { rgba } from 'polished';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Colors } from 'theme/colors';

interface CardProps {
    selected?: boolean;
    noMargin?: boolean;
    smallMargin?: boolean;
    overflow?: 'visible' | 'hidden' | 'scroll' | 'auto';
    variant?: keyof Colors;
}

const Card = styled.div<CardProps>`
    background-color: ${({ theme, variant }) => variant ? theme.colors[variant] : theme.colors.white};
    box-shadow: 0 3px 6px ${({ theme }) => rgba(theme.colors.primary, 0.1)};
    border-radius: 12px;
    margin-bottom: ${({ noMargin, smallMargin }) => (noMargin ? 0 : smallMargin ? '2px' : '1.25rem')};
    overflow: ${({ overflow }) => overflow};
    position: relative;

    ${({ selected, theme }) =>
        selected &&
        css`
            box-shadow: 0 10px 30px ${rgba(theme.colors.primary, 0.2)};
        `}
        
    ${({ variant, theme }) =>
        variant === 'background' &&
        css`
        hr {
            border-color: ${theme.colors.accent};
        }
    `}
`;

export default Card;
