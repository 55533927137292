export const lightenAmount = {
    disabled: 0.35,
    active: 0.1,
    hover: 0.05
};

const colors = {
    white: '#ffffff',
    black: '#000000',
    background: '#F3F3F5',
    accent: '#F3F3F5',
    grey: '#959595',
    dark: '#282828',
    text: '#282828',
    primary: '#213F38',
    secondary: '#E7FDAB',
    red: '#E0054C',
    green: '#DAFC89',
    orange: '#FF6B35',
    warning: '#FFAF33',
};

export type Colors = typeof colors;

export default colors;
