import React, { FC } from 'react';
import Group from 'components/molecules/Group';
import { FormControl } from 'components/molecules/form';

export interface SearchProps {
    filter: string;
    setFilter: (value: string) => void;
}

const Search: FC<SearchProps> = ({ filter, setFilter }) => {
    const handleOnChange = (value: string) => {
        setFilter(value);
    };

    return (
        <Group>
            <FormControl icon="search" name="search" placeholder="Zoeken..." value={filter} onChange={handleOnChange} />
        </Group>
    );
};

export default Search;
