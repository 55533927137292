import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { CustomError, useApi } from '..';
import { EditTagInputModel, SearchTagInputModel, TagModel } from './types';

const useTag = () => {
    const { apiGet, apiPost, apiPut, apiDelete } = useApi();

    const baseUrl = '/tag';

    const addOrEditTag = async (values: EditTagInputModel) => {
        const fd = new FormData();
        appendObject(values, fd);

        const call = values.id == null
            ? apiPost(baseUrl, fd)
            : apiPut(baseUrl, fd);

        const response = await call;

        return response;
    };

    const getTagById = async (id: string) => {
        const response = await apiGet<TagModel>(`${baseUrl}/${id}`);

        return response;
    };

    const searchTags = async (values: SearchTagInputModel) => {
        const response = await apiPost<TagModel[]>(`${baseUrl}/search`, values);

        return response;
    };

    const useSearchTags = (params: SearchTagInputModel, enabled = true) => {
        const queryKey = ['tag.search', params, enabled];

        return useQuery<TagModel[], CustomError[]>(queryKey, async () => {
            const response = await searchTags(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        }, {
            enabled
        });
    };

    const deleteTag = async (id: string) => {
        const response = await apiDelete(`${baseUrl}/${id}`);

        return response;
    };

    return {
        addOrEditTag,
        useSearchTags,
        searchTags,
        getTagById,
        deleteTag
    };
};

export default useTag;
