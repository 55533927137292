import React from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { AddButton, Wrapper, RemoveButton, Row } from './styles'; // Import RemoveButton
import Group from 'components/molecules/Group';
import Icon from 'components/atoms/Icon';
import { useTranslation } from 'react-i18next';

interface FormArrayProps {
    name: string;
    renderFields: (index: number, values: []) => React.ReactNode;
    addText?: string;
}

const FormArray: React.FC<FormArrayProps> = ({ name, renderFields, addText }) => {
    const { t } = useTranslation();
    
        return (<FieldArray name={name}>
            {(arrayHelpers: FieldArrayRenderProps) => (
                <Wrapper>
                    {arrayHelpers.form.values[name].map((_: any, index: number) => (
                        <Row key={index}>
                            {renderFields(index, arrayHelpers.form.values[name])}
                            <RemoveButton type="button" onClick={() => arrayHelpers.remove(index)}>
                                <Icon name="bin" size={1.2} />
                            </RemoveButton>
                        </Row>
                    ))}
                    <Group right>
                        <AddButton type="button" onClick={() => arrayHelpers.push({})}>
                            <Icon name="add" size={1} /> <div>{addText ?? t('add')}</div>
                        </AddButton>
                    </Group>
                </Wrapper>
            )}
        </FieldArray>
    );
};

export default FormArray;
