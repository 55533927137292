export interface Greys {
  [key: number]: string;
}

const greys: Greys = {
  25: '#F5F7F8',
  50: '#ECEFF1',
  100: '#CFD8DC',
  300: '#90A4AE',
  500: '#607D8B',
  700: '#455A64',
  900: '#263238',
};

export default greys;
