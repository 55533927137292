import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

export const FormSelect = styled.div<{ isFocused: boolean; isSmall?: boolean; isDisabled?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: ${({ theme, isSmall }) => (isSmall ? theme.form.height.small : theme.form.height.normal)}rem;
    border: 2px solid ${({ theme, isFocused }) => isFocused ? theme.colors.secondary : theme.colors.accent};
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    padding-right: 2.5rem;

    ${({ isFocused }) =>
        isFocused &&
        css`
            outline: none;
        `}

    ${({ isDisabled, theme }) =>
        isDisabled &&
        css`
            background-color: ${theme.greys[25]};
        `}
`;

export const ValueContainer = styled.div`
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0 0 0 16px;
    align-items: center;    
    font-size: 0.85rem;
    font-weight: 500;
`;

export const Option = styled.div<{ isSelected: boolean; isDisabled: boolean; }>`
    display: inline-flex;
    padding: 1rem 1.5rem;
    width: 100%;
    align-items: center;
    border-radius: .375rem;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 2px;

    &:hover {
        background-color: ${({ theme }) => theme.greys[50]};
    }

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            background-color: ${theme.greys[50]};
            border-top-color: transparent;

            + div {
                border-top-color: transparent;
            }

            &:hover {
                background-color: ${theme.greys[25]};
            }
        `}
    
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.5;
        `}
`;

export const Menu = styled.div<{ placement?: string; }>`
    box-shadow: 0 5px 20px ${({ theme }) => rgba(theme.colors.dark, 0.15)};
    border-radius: 12px;
    margin-top: 0.5rem;
    overflow: hidden;
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 99;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ placement }) =>
        placement === 'top' &&
        css`
            top: initial;
            bottom: 100%;
            margin-top: 0;
            margin-bottom: 0.5rem;
    `}
`;

export const MenuList = styled.div`
    padding: .5rem;
    max-height: 28.5rem;
    overflow-y: auto;
`;

export const Placeholder = styled.div`
    color: ${({ theme }) => theme.greys[300]};
`;

export const Indicator = styled.div`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-shrink: 0;
    align-self: center;
    margin-top: 2px;
`;
