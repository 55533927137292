import React, { FC, useEffect, useState, useMemo } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import Dropzone from 'react-dropzone';
import { Content, Drop, Remove, Wrapper } from './styles';
import Icon from 'components/atoms/Icon';
import { Button, IconButton } from 'components/atoms/button';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export interface FormDropProps {
    error?: string;
    value?: File | string;
    onChange?: (value?: File) => void;
    height?: number,
    buttonText?: string
    hidePreview?: boolean;
}

const FormDrop: FC<FormDropProps> = ({ error, value, onChange, height, buttonText, hidePreview }) => {
    const hasValue = useMemo(() => value != null, [value]);
    const [url, setUrl] = useState('');

    const { t } = useTranslation('common');

    useEffect(() => {
        if (value == null) {
            return;
        }

        if (value instanceof File) {
            setUrl(URL.createObjectURL(value));
        } else {
            setUrl(value);
        }
    }, [value]);

    // Methods.
    const handleOnDrop = (files: File[]) => {
        if (onChange && files.length > 0) {
            onChange(files[0]);

            if (hidePreview) {
                toast.success(t('fileChanged'));
            }
        }
    };

    const handleRemove = () => {
        if (onChange) {
            onChange(undefined);
            setUrl('');
        }
    };

    // Render.
    return (
        <Wrapper>
            {
                hasValue &&
                <Remove onClick={handleRemove}>
                    <Icon name="close" size={1} />
                </Remove>
            }
            <Dropzone onDrop={handleOnDrop} maxFiles={1}>
                {({ getRootProps, getInputProps, open }) => (
                    <>
                        <Drop {...getRootProps()} height={height} image={url} hide={hidePreview}>
                            <input {...getInputProps()} />
                            <Content />
                        </Drop>
                        <Button onClick={open} variant="white" title={buttonText} type="button" />
                        { 
                            hidePreview && url !== '' &&
                            <a href={url} target="blank">
                                <IconButton type="button" icon="download" variant="white" />
                            </a>
                        }
                    </>
                )}
            </Dropzone>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </Wrapper>
    );
};

export default FormDrop;

