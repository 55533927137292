import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ isSmall?: boolean; isFocused: boolean; error?: boolean; notification?: boolean; }>`
    height: ${({ theme, isSmall }) => (isSmall ? theme.form.height.small : theme.form.height.normal)}rem;
    align-items: center;
    border-radius: 50px;
    display: flex;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ theme, isFocused, error, notification }) => {
        if (error) {
            return css`
            border: 2px solid ${theme.colors.red};
        `;
        } else if (notification) {
            return css`
            border: 2px solid ${theme.colors.warning};
        `;
        } else if (isFocused) {
            return css`
            border: 2px solid ${theme.colors.secondary};
        `;
        } else {
            return css`
            border: 2px solid ${theme.colors.accent};
        `;
        }
    }}
`;
export const FormControl = styled.input<{ isSmall?: boolean }>`
    padding: 0;
    margin-left: 1rem;
    margin-bottom: .25rem;
    border: 1px solid ${({ theme }) => theme.colors.white};
    cursor: pointer;
    font-size: ${({ theme, isSmall }) => (isSmall ? theme.form.fontSize.small : theme.form.fontSize.normal)}rem;
    font-weight: 400;
    background-color: ${({ theme }) => theme.colors.white};

    &:focus {
        outline: none;
    }

    ::placeholder {
        color: ${({ theme }) => theme.greys[300]};
    }

    &::-webkit-color-swatch {
        border: none;
        border-radius: 6px;
        padding: 0;
        height: 2rem;
        width: 2rem;
    }

    &::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 6px;
        padding: 0;
        height: 2rem;
        width: 2rem;
    }
`;

export const InlineWrapper = styled.div<{ isSmall?: boolean }>`
    height: 100%;
    width: ${({ theme, isSmall }) => (isSmall ? theme.form.height.small : theme.form.height.normal)}rem;
    flex: 0 0 ${({ theme, isSmall }) => (isSmall ? theme.form.height.small : theme.form.height.normal)}rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
`;

export const Value = styled.div`
    color: ${({ theme }) => theme.colors.grey};
`;

export const RemoveButton = styled.button`
    border: none;
    background-color: transparent;
    margin-left: 20px;
`;
