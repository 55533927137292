import React, { FC, PropsWithChildren, useState } from 'react';
import { Paragraph as Text, ReadMore } from './styles';
import { useTheme } from '@emotion/react';
import { Colors } from 'theme/colors';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';

export interface ParagraphProps {
    variant?: keyof Colors;
    center?: boolean;
    className?: string;
    opacity?: number;
    noMargin?: boolean;
    characterLimit?: number;
    fontSize?: number;
}

const Paragraph: FC<PropsWithChildren<ParagraphProps>> = ({ children, variant = 'black', center, opacity = 1, className, noMargin, characterLimit, fontSize }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const color = theme.colors[variant];
    const [readMoreActive, setReadMoreActive] = useState<boolean>(!!characterLimit);

    return (
        <Text color={rgba(color, opacity)} center={center} className={className} noMargin={noMargin} fontSize={fontSize}>
            {
                (readMoreActive && children && characterLimit && children.toString().length > characterLimit) ? (
                    <>
                        {children?.toString().slice(0, characterLimit)}...
                        <ReadMore onClick={() => setReadMoreActive(false)}>{t('actions.readMore')}</ReadMore>
                    </>
                ) : (
                    <>
                        {children}
                    </>
                )
            }
        </Text>
    );
};

export default Paragraph;
