import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Table as ReactTable,
} from '@tanstack/react-table';
import Content from 'components/atoms/Content';
import Group from 'components/molecules/Group';
import ContentHead from 'components/molecules/ContentHead';
import TableSearch from 'components/molecules/table/TableSearch';
import { Button } from 'components/atoms/button';
import Tabs, { Tab } from 'components/molecules/Tabs';
import Margin from 'components/atoms/Margin';
import HeadTags from './components/HeadTags';
import SubTags from './components/SubTags';
import { useModal } from 'hooks/modals';
import Modal from 'components/organisms/Modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { AddTagGroupInputModel } from 'hooks/api/tagGroup/types';
import { FormGroup, FormikFormSelect } from 'components/molecules/form';
import Label from 'components/atoms/Label';
import useTagGroup from 'hooks/api/tagGroup';
import { toast } from 'react-toastify';
import useTenantTag from 'hooks/api/tenantTag';
import { toFormikErrors } from 'utils/errorHelper';
import { AddCategoryGroupInputModel } from 'hooks/api/categoryGroup/types';
import useCategoryGroup from 'hooks/api/categoryGroup';
import useTenantCategory from 'hooks/api/tenantCategory';
import { useLocation, useNavigate } from 'react-router-dom';
import useCopyToTenantModal from 'hooks/modals/useCopyToTenantModal';
import { TenantCategoryModel } from 'hooks/api/tenantCategory/types';
import useChangeOrder from 'hooks/modals/useChangeOrder';
import { useTranslation } from 'react-i18next';
import { BaseCopyToTenantInputModel } from 'hooks/api/types';

export interface MergeTag {
    id: string;
    name: string;
    reference: string;
}

export enum TagsOverviewTabs {
    Categories = 0,
    Tags = 1
}

const TagsOverview: FC = () => {
    const { t } = useTranslation('tagsOverview');

    const [tabIndex, setTabIndex] = useState(TagsOverviewTabs.Categories);
    const navigate = useNavigate();

    const { addCategoryGroup } = useCategoryGroup();
    const { addTagGroup } = useTagGroup();

    const { useSearchTenantTags, copyTagsToTenant } = useTenantTag();
    const { data: subTags = [], refetch: refetchTags } = useSearchTenantTags();

    const { useSearchTenantCategories, copyCategoriesToTenant } = useTenantCategory();
    const { data: categories = [], refetch: refetchCategories } = useSearchTenantCategories();

    const [mergeCategories, setMergeCategories] = useState<MergeTag[]>([]);
    const [mergeTags, setMergeTags] = useState<MergeTag[]>([]);
    
    const countSelectedCategories = mergeCategories.length;
    const countSelectedTags = mergeTags.length;
    const isHeadTagPage = tabIndex === TagsOverviewTabs.Categories;

    const tagTable = useRef<ReactTable<any>>(null);
    const headTagTable = useRef<ReactTable<any>>(null);
    const activeTable = isHeadTagPage ? headTagTable : tagTable;

    const [showOrderModal] = useChangeOrder<TenantCategoryModel>();

    const { editTenantCategoryOrder } = useTenantCategory();

    const [showCopyToTenantModal] = useCopyToTenantModal({
        title: `${isHeadTagPage ? 'Categorieën' : 'Tags'} naar ander platform kopiëren`,
        isSoftCopy: isHeadTagPage,
        onConfirm: async (values, setErrors, hide) => {
            const data: BaseCopyToTenantInputModel = {
                ids: (isHeadTagPage ? mergeCategories : mergeTags).map(t => t.id),
                ...values,
            };

            const response = isHeadTagPage
                ? await copyCategoriesToTenant(data)
                : await copyTagsToTenant(data);

            if (!response.ok && response.errors != null) {
                setErrors(toFormikErrors(response.errors));
            } else {
                toast.success(`${isHeadTagPage ? 'Categorieën' : 'Tags'} zijn gekopieerd`);
                setMergeTags([]);
                hide();
            }
        }
    });

    useEffect(() => {
        setMergeTags([]);
    }, [tabIndex]);

    const onChangeMergeTags = useCallback((mergeTag: MergeTag) => {
        let array = isHeadTagPage ? [...mergeCategories] : [...mergeTags];

        const index = array.findIndex((t) => t.id === mergeTag.id);

        if (index === -1) {
            array.push(mergeTag);
        } else {
            array = array.filter((t) => t.id !== mergeTag.id);
        }

        if (isHeadTagPage) {
            setMergeCategories(array);
        } else {
            setMergeTags(array);
        }
    }, [isHeadTagPage, mergeTags, mergeCategories]);

    const selectAll = () => {
        const selected = isHeadTagPage ? [...mergeCategories] : [...mergeTags];
        const all = isHeadTagPage ? [...categories] : [...subTags];

        if (selected.length === all.length) {
            if (isHeadTagPage) {
                setMergeCategories([]);
            } else {
                setMergeTags([]);
            }

            return;
        }

        if (isHeadTagPage) {
            setMergeCategories(categories.map((tc) => ({
                id: tc.category.id,
                name: tc.category.name,
                reference: tc.reference
            })));
        } else {
            setMergeTags(subTags.map((st) => ({
                id: st.tag.id,
                name: st.tag.name,
                reference: st.reference
            })));
        }
    };

    const handleMergeTags = async (values: AddTagGroupInputModel | AddCategoryGroupInputModel, { setSubmitting, setErrors }: FormikHelpers<AddTagGroupInputModel | AddCategoryGroupInputModel>) => {
        const response = isHeadTagPage
            ? await addCategoryGroup(values as AddCategoryGroupInputModel)
            : await addTagGroup(values as AddTagGroupInputModel);

        if (response.ok) {
            hideMergeModal();
            toast.success('Tags zijn samengevoegd');
            isHeadTagPage ? refetchCategories() : refetchTags();
            isHeadTagPage ? setMergeCategories([]) : setMergeTags([]);
        } else if (response.errors) {
            const errors = toFormikErrors<AddTagGroupInputModel | AddCategoryGroupInputModel>(response.errors);
            setErrors(errors);
            toast.error('Er is iets fout gegaan bij het samenvoegen van de tags');
        }

        setSubmitting(false);
    };

    const initialValues: AddTagGroupInputModel | AddCategoryGroupInputModel = useMemo(() => {
        if (isHeadTagPage) {
            return {
                primaryCategoryId: null,
                categoryIds: mergeCategories.map((category) => category.id)
            };
        } else {
            return {
                primaryTagId: null,
                tagIds: mergeTags.map((tag) => tag.id)
            };
        }
    }, [mergeTags, mergeCategories, isHeadTagPage]);

    const mergeTagOptions = (isHeadTagPage ? mergeCategories : mergeTags).map((tag) => ({
        label: `${tag.name} (${tag.reference})`,
        value: tag.id
    }));

    const onChangePrimaryTag = (
        value: string | number | (string | number)[] | undefined,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    ) => {
        const tagIds = (isHeadTagPage ? mergeCategories : mergeTags).filter((tag) => tag.id !== value).map((tag) => tag.id);
        setFieldValue(isHeadTagPage ? 'categoryIds' : 'tagIds', tagIds);
    };

    const [showMergeModal, hideMergeModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Formik<AddTagGroupInputModel | AddCategoryGroupInputModel>
                initialValues={initialValues}
                onSubmit={handleMergeTags}
                enableReinitialize
            >
                {({ submitForm, isSubmitting, setFieldValue }) => (
                    <Modal
                        onHide={hideMergeModal}
                        inProp={inProp}
                        onExited={onExited}
                        title={`${isHeadTagPage ? 'Categorieën' : 'Tags'} samenvoegen`}
                        buttons={[
                            {
                                title: 'Bevestigen',
                                onClick: submitForm,
                                variant: 'primary',
                                loading: isSubmitting
                            }
                        ]}
                    >
                        <Form>
                            <FormGroup label={`Geselecteerde ${isHeadTagPage ? 'categorieën' : 'tags'}`}>
                                <Group noMargin gap={.5}>
                                    {
                                        (isHeadTagPage ? mergeCategories : mergeTags).map((tag) => <Label text={`${tag.name} ${tag.reference ? `(${tag.reference})` : ''}`} />)
                                    }
                                </Group>
                            </FormGroup>
                            <FormGroup label={`Selecteer een ${isHeadTagPage ? 'categorieën' : 'tags'}`}>
                                <FormikFormSelect name={isHeadTagPage ? 'primaryCategoryId' : 'primaryTagId'} options={mergeTagOptions} customOnChange={(value) => onChangePrimaryTag(value, setFieldValue)} />
                            </FormGroup>
                        </Form>
                    </Modal>
                )}
            </Formik>
        ),
        [handleMergeTags, initialValues, isHeadTagPage]
    );

    const { search } = useLocation();

    useEffect(() => {
        const tab = new URLSearchParams(search).get('tab');
        if (tab != null) {
            setTabIndex(Number(tab));
        }
    }, [search]);

    const tabs: Tab[] = [
        {
            title: 'Categorieën'
        },
        {
            title: 'Tags',
        }
    ];

    const onCopyToTenantModal = useCallback(showCopyToTenantModal, []);

    const onChangeOrder = useCallback(() => {
        showOrderModal({
            items: categories,
            orderKey: ['order', 'asc'],
            idKey: (item) => item.id,
            nameKey: (item) => item.category.name,
            onSave: async (items) => {
                const response = await editTenantCategoryOrder({ order: items.map((item, index) => ({ id: item.id, order: index })) });
                return response.ok;
            },
            refetchData: () => refetchCategories(),
        });
    }, [categories]);

    const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);

    const mergeBtnTitle = isHeadTagPage 
        ? t('mergeCategories', { countCategoriesToMerge: `${countSelectedCategories > 0 ? `${countSelectedCategories}` : ''}` }) 
        : t('mergeTags', { countTagsToMerge: `${countSelectedTags > 0 ? `${countSelectedTags}` : ''}` });
    
    const deleteBtnTitle = t(`deleteTags.${countSelectedTags === 1 ? 'single' : 'multiple' }`, {
        countTagsToDelete: `${countSelectedTags > 1 ? `${countSelectedTags}` : ''}`
    });

    return (
        <>
            <Content>
                <ContentHead spaceBetween title={isHeadTagPage ? 'Categorieën' : 'Tags'}>
                    <Group>
                        {tabIndex === 0 && (
                            <Button variant="white" onClick={onChangeOrder}>{t('changeCategoryOrder')}</Button>
                        )}
                        <TableSearch onChange={(value) => activeTable.current?.setGlobalFilter(value)} placeholder={`Zoek ${isHeadTagPage ? 'categorie' : 'tag'}`} />
                        <Button onClick={() => isHeadTagPage ? navigate('category/new') : navigate('new')}>{isHeadTagPage ? 'Categorie' : 'Tag'} toevoegen</Button>
                    </Group>
                </ContentHead>
                <Margin bottom={1}>
                    <Group spaceBetween>
                        <Tabs tabs={tabs} activeTab={tabIndex} onTabClick={setTabIndex} />
                        <Group>
                            <Button variant="white" onClick={showMergeModal}>
                                {mergeBtnTitle}
                            </Button>

                            <Button onClick={onCopyToTenantModal} disabled={isHeadTagPage ? countSelectedCategories === 0 : countSelectedTags === 0}>
                                {t('copyToTenant', { copyToTenant: isHeadTagPage ? 'Categorieën' : 'Tags' })}
                            </Button>

                            {
                                !isHeadTagPage &&
                                <>
                                    <Button onClick={() => setShowDeleteTagModal(!showDeleteTagModal)} disabled={countSelectedTags === 0} variant="red">
                                        {deleteBtnTitle}
                                    </Button>
                                </>
                            }
                        </Group>
                    </Group>
                </Margin>
                {
                    isHeadTagPage &&
                    <HeadTags
                        categories={categories}
                        tableRef={headTagTable}
                        onChangeMergeTags={onChangeMergeTags}
                        mergeCategories={mergeCategories}
                        selectAllCategories={selectAll}
                    />
                }
                {
                    !isHeadTagPage &&
                    <SubTags
                        tableRef={tagTable}
                        tags={subTags}
                        mergeTags={mergeTags}
                        setMergeTags={setMergeTags}
                        selectAllTags={selectAll}
                        onChangeMergeTags={onChangeMergeTags}
                        openDeleteTagPopup={showDeleteTagModal}
                        setOpenDeleteTagPopup={setShowDeleteTagModal}
                    />
                }
            </Content>
        </>
    );
};

export default TagsOverview;
