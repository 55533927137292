import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

export const FormSwitch = styled.label`
    display: inline-block;
    width: 3rem;
    height: 1.75rem;
    position: relative;
    flex-shrink: 0;

    .switch {
        width: 3rem;
        height: 1.75rem;
        position: absolute;
        top: 0;
        background: ${({ theme }) => theme.colors.accent};
        border-radius: 1rem;

        &:hover {
            cursor: pointer;
        }

        &:before {
            position: absolute;
            content: '';
            width: calc(1.75rem - 4px);
            height: calc(1.75rem - 4px);
            background-color: ${({ theme }) => theme.colors.white};
            box-shadow: 0 5px 20px ${({ theme }) => rgba(theme.colors.dark, 0.1)};
            position: absolute;
            top: 2px;
            left: 2px;
            border-radius: 50%;
            transition: left 0.3s ease;
        }
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .switch {
            background-color: ${({ theme }) => theme.colors.primary};
            background: ${({ theme }) => theme.colors.primary};

            &:before {
                left: calc(1.5rem - 2px);
            }
        }
    }
`;

export const Wrapper = styled.div<{ reverse?: boolean; }>`
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    
    > div {
        margin-left: .75rem;
    }
    
    ${({ reverse }) =>
        reverse &&
        css`
            > label {
                order: 2;
            } 
            > div {
                order: 1;
                margin-right: .75rem;
                margin-left: 0;
            }
        `}
`;


export const SubTitle = styled.span`
    opacity: .5;
    display: block;
    font-weight: 400;
    margin-top: .5rem;
    font-size: .85em;
`;

export const LabelBefore = styled.span`
    margin-left: 0;
    margin-right: .75rem;
`;

export const LabelBeforeWrapper = styled.div`
    margin-left: 0!important;
`;
