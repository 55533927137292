import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormRadioProps } from '.';
import { lighten } from 'polished';

export const Checkbox = styled.div<{
    checked?: boolean;
}>`
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.colors.accent};
    border-radius: 50%;
    display: flex;
    flex: 0 0 16px;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ theme, checked }) =>
        checked &&
        css`
            background-color: ${theme.colors.black};
            border: 3px solid ${theme.colors.white};

            &:hover {
                background-color: ${lighten(0.1, theme.colors.black)};
            }
        `}
`;

export const HiddenCheckbox = styled.input<FormRadioProps>`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:focus + div {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
    }
`;

export const Label = styled.span`
    color: ${({ theme }) => theme.colors.dark};
    margin-left: 16px;
    font-size: 0.875rem;
    line-height: 0.875rem;
`;

export const Wrapper = styled.label<{
    label?: string;
}>`
    display: ${({ label }) => (label ? 'flex' : 'inline-flex')};
    align-items: center;
    user-selet: none;

    &:hover {
        cursor: pointer;
    }
`;
