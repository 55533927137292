import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { Pagination as Wrapper, Button, Right, Select } from './styles';
import { Paragraph } from 'components/atoms/text';
import Margin from 'components/atoms/Margin';
import Group from 'components/molecules/Group';

export interface PaginationProps {
    pageIndex: number;
    getPageCount: () => number;
    gotoPage: (updater: number) => void;
    nextPage: () => void;
    previousPage: () => void;
    getCanPreviousPage: () => boolean;
    getCanNextPage: () => boolean;
    setPageSize?: (pageSize: number) => void;
    pageSize?: number;
}

const pagination = (current: number, length: number, isMobile?: boolean) => {
    const delta = isMobile ? 1 : 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];

    for (let i = 1; i <= length; i++) {
        if (i === 1 || i === length || (i >= left && i < right)) {
            range.push(i);
        }
    }

    let l: number | undefined = undefined;
    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push(undefined);
            }
        }

        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
};

const Pagination: FC<PaginationProps> = ({ pageIndex, getPageCount, gotoPage, previousPage, nextPage, getCanPreviousPage, getCanNextPage, setPageSize, pageSize }) => {
    const theme = useTheme();
    const pageCount = getPageCount();
    const canNextPage = getCanNextPage() || false;
    const canPreviousPage = getCanPreviousPage() || false;
    const pages = pagination(pageIndex, pageCount);

    return (
        <Wrapper>
            {
                (setPageSize && pageSize) &&
                <>
                    <Paragraph fontSize={.75} variant="grey" noMargin>toon
                        <Select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 50, 100, 250].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </Select>
                        resultaten per pagina
                    </Paragraph>
                </>
            }
            <Right>
                <Paragraph noMargin fontSize={.75}>
                    {(pageIndex) * (pageSize || 10) + 1}-{(pageIndex + 1) * (pageSize || 10)}
                </Paragraph>
                <Margin left={.25}>
                    <Paragraph variant="grey" noMargin fontSize={.75}>
                        van {(pages.at(-1) || 1) * (pageSize || 10)} resultaten
                    </Paragraph>
                </Margin>
                <Margin left={1}>
                    <Group noMargin>
                        <Button onClick={previousPage} canNavigate={canPreviousPage} disabled={!canPreviousPage}>
                            <Icon name="double-left" color={theme.colors.text} size={1} />
                        </Button>
                        <Margin left={.25} right={.313}>
                            <Paragraph noMargin fontSize={.75}>{pageIndex + 1}</Paragraph>
                        </Margin>
                        <Button onClick={nextPage} canNavigate={canNextPage} disabled={!canNextPage}>
                            <Icon name="double-right" color={theme.colors.text} size={1} />
                        </Button>
                    </Group>
                </Margin>
            </Right>
        </Wrapper>
    );
};

export default Pagination;

